import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PopulationService} from "../../../../../services/population/population.service";
import {Observable} from "rxjs";
import {PopulationSettingsDto} from "../../../../../models/dto/population/populationSettingsDto";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {PopulationPrey} from "../../../../../models/dto/population/populationPrey";
import {MatDrawer} from "@angular/material/sidenav";
import {LocationService} from "../../../../../services/location/location.service";
import {ItemResponseDto} from "../../../../../models/dto/response/itemResponseDto";
import {Location} from "../../../../../models/location/location.model";

export interface SettingsEntry<T> {
  property: string;
  name: string;
  value: any;
  help: string;
  type: T;
  settingsGroup: string;
}
@Component({
  selector: 'app-population-settings-component',
  templateUrl: './population-settings-component.component.html',
  styleUrls: ['./population-settings-component.component.scss']
})
export class PopulationSettingsComponentComponent implements OnInit, OnChanges {
  @Input() population: PopulationDto | undefined;
  @Input() canLoad: boolean = false;

  @ViewChild(MatDrawer) drawer!: MatDrawer;

  public mainSettingsDataSource: any;
  public submissionSettingsDataSource: any;
  public locationSettingsDataSource: any;

  public settingsObs: Observable<PopulationSettingsDto> | undefined;
  public settings: PopulationSettingsDto | undefined;
  public prey: Array<PopulationPrey> | undefined;
  public fetched: boolean = false;
  public editing: Map<string, boolean> | undefined;
  adding: boolean = false;
  newPrey: PopulationPrey | undefined;

  public displayedColumns = ['name', 'value', 'help'];

  constructor(
    private populationService: PopulationService,
    private log: ILoggingService,
    private locationService: LocationService
  ) {
}

  ngOnInit(): void {
    if (this.canLoad) {
      if (this.population !== undefined && this.population.id !== undefined) {
        this.populationService.getPopulationSettings(this.population.id)
          .subscribe(res => {
            this.settings = res;
            this.populateElements();
          })

      }
    }

  }

  populateElements() {
    if (!this.settings) return;
    const mainSettings = new Array<SettingsEntry<any>>();
    mainSettings.push(
      {
        name: "Population Display Name",
        value: this.population?.displayName,
        help: "The name used for primary identification",
        property: "displayName",
        type: String,
        settingsGroup: "main"
      },
      {
        name: "Population Species Name",
        value: this.population?.speciesName,
        help: "The name used for species identification",
        property: "speciesName",
        type: String,
        settingsGroup: "main"
      },
      {
        name: "Abbreviation",
        value: this.population?.abbreviation,
        help: "The name used for species abbreviation",
        property: "abbreviation",
        type: String,
        settingsGroup: "main"
      }
    );
    this.mainSettingsDataSource = mainSettings;

    const submissionSettings = new Array<SettingsEntry<any>>();
    submissionSettings.push({
      name: "Require Join Request",
      value: this.settings.requireJoinRequest,
      help: "If true, users must request to join the population",
      property: "requireJoinRequest",
      type: Boolean,
      settingsGroup: "submission"
    },{
      name: "Auto Tag Single Entries",
      value: this.settings.submissionAutoTag,
      help: "If true, if one individual detected in the image and one available (existing) ID in the caption, the detected individual will be automatically labeled",
      property: "submissionAutoTag",
      type: Boolean,
      settingsGroup: "submission"
    })

    this.submissionSettingsDataSource = submissionSettings;

    const locationSettings = new Array<SettingsEntry<any>>();
    locationSettings.push({
      name: 'Location Name',
      value: this.population?.center?.name,
      help: 'This is used to identify where on earth this population will most commonly be seen',
      property: "name",
      type: String,
      settingsGroup: "location"
    })

    this.locationSettingsDataSource = locationSettings;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["canLoad"] !== undefined) {
      if (changes["canLoad"]) {
        this.ngOnInit();
      }
    }
  }



  updateMainSettings(population: PopulationDto) {
    this.populationService
      .updatePopulationDetails(population.id!, population)
      .subscribe({
        next: (population: PopulationDto) => {
        this.log.info("Population successfully updated", true)
      },
        error: (error: HttpErrorResponse) => {
        this.log.error(`Could not update population: ${error.message}`)
        }
      })
  }

  updateSettings(settings: PopulationSettingsDto) {
    this.populationService
      .updatePopulationSettings(this.population!.id!, settings)
      .subscribe({
        next: (population: PopulationSettingsDto) => {
          this.log.info("Population successfully updated", true)
        },
        error: (error: HttpErrorResponse) => {
          this.log.error(`Could not update population: ${error.message}`)
        }
      })
  }

  updateLocationSettings(location: Location) {
    this.locationService.updateLocation(location).subscribe({
      next: (value: ItemResponseDto<Location>)=> {
        if (value.successful) {
          this.log.info(`Location information successfully updated`)
        } else {
          this.log.info(`Could not update location: ${value.errorMessages.join(', ')}`)
        }
    }, error: (value: HttpErrorResponse) => {
        this.log.error(`Could not update location: ${value.message}`)
    }
    })
  }

  public addPreyTarget() {
    this.newPrey = {
      displayName: '',
      populationId: this.population?.id
    }
    this.adding = true;
  }

  private initializePrey() {
    this.populationService
      .getPopulationPrey(this.population!.id!)
      .subscribe({
        next: (value: Array<PopulationPrey>) => {
          this.prey = value;
          this.fetched = true;
          this.initMap();

        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not fetch prey: ${value.message}`);
          this.fetched = true;
        }
      })
  }

  private initMap() {
    this.editing = new Map<string, boolean>();
    for (let i of this.prey!) {
      this.editing.set(i.id!, false);
    }
  }

  createTarget() {
    this.populationService
      .addPopulationPrey(this.population!.id!, this.newPrey!)
      .subscribe({
        next: (value: PopulationPrey) => {
          this.log.info(`${value.displayName} added`);
          this.adding = false;
          this.initializePrey();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not create prey: ${value.message}`);
          this.adding = false;
          this.initializePrey();
        }
      })
  }

  cancelAdding() {
    this.adding = false;
  }

  activateAdding(id: string) {
    for (let i of this.prey!) {
      this.editing!.set(i.id!, false);
    }
    this.editing!.set(id, true);
  }

  updateTarget(p: PopulationPrey) {
    this.populationService
      .updatePopulationPrey(this.population!.id!, p)
      .subscribe({
        next: (value: PopulationPrey) => {
          this.log.info(`${value.displayName} updated`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not update prey: ${value.message}`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        }
      })
  }

  deleteTarget(p: PopulationPrey) {
    this.populationService
      .deletePopulationPrey(this.population!.id!, p.id!)
      .subscribe({
        next: (value: PopulationPrey) => {
          this.log.info(`${p.displayName} deleted`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not delete prey: ${value.message}`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        }
      })
  }


  activeElement?: SettingsEntry<any>;


  startEdit(element: SettingsEntry<any>) {
    this.activeElement = element;
    this.drawer.open();
  }

  cancelEdit() {
    this.drawer.close();
  }

  save(activeElement: SettingsEntry<any>) {
    if (!this.population || !this.settings) return;
    if (activeElement.settingsGroup == "main") {
      //@ts-ignore
      this.population[activeElement.property] = activeElement.value;
      this.updateMainSettings(this.population);
    } else if (activeElement.settingsGroup == "submission") {
      //@ts-ignore
      this.settings[activeElement.property] = activeElement.value;
      this.updateSettings(this.settings);
    } else if (activeElement.settingsGroup == "location") {
      //@ts-ignore
      this.population.center[activeElement.property] = activeElement.value;
      console.log(this.population.center);
      this.updateLocationSettings(this.population.center!)
    }
    this.drawer.close();
  }

  getType(activeElement: SettingsEntry<any>) {
    if (activeElement.type === String) {
      return 'string';
    } else if (activeElement.type === Boolean) {
      return 'bool';
    }

    return 'unknown';

  }
}

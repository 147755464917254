<div class="content-container">
  <div class="content"  *ngIf="population; else loading">
    <div class="content-header">
      <div class="">
        <h1>{{ population.displayName }}</h1>
      </div>
    </div>
    <mat-drawer-container class="drawer-container">
      <mat-drawer mode="side" opened class="drawer">

        <mat-action-list>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Individuals'}"  (click)="loadModule('Individuals')">Individuals</button>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Settings'}" (click)="loadModule('Settings')">Settings</button>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Contributors'}"  (click)="loadModule('Contributors')">Contributors</button>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Members'}"  (click)="loadModule('Members')">Members</button>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Requests'}"  (click)="loadModule('Requests')">Membership Requests</button>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Data'}"  (click)="loadModule('Data')">Data Access</button>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Analysis'}"  (click)="loadModule('Analysis')">Analysis</button>
        </mat-action-list>

      </mat-drawer>
      <mat-drawer-content>
        <div class="drawer-content">
          <h3>{{activeModule}}</h3>

          <div *ngIf="activeModule=='Settings'">
            <app-population-settings-page></app-population-settings-page>
          </div>
          <div *ngIf="activeModule=='Contributors'">
            <app-population-contributors-page></app-population-contributors-page>
          </div>
          <div *ngIf="activeModule=='Members'">
            <app-population-members-page></app-population-members-page>
          </div>
          <div *ngIf="activeModule=='Individuals'">
            <app-population-individuals></app-population-individuals>
          </div>
          <div *ngIf="activeModule=='Requests'">
            <app-population-membership-requests></app-population-membership-requests>
          </div>
          <div *ngIf="activeModule=='Data'">
            <app-population-data-access></app-population-data-access>
          </div>
          <div *ngIf="activeModule=='Analysis'">
            <app-population-analysis></app-population-analysis>
          </div>

        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>





<!--<div class="content-container">-->
<!--  <mat-card class="example-card">-->
<!--    <mat-card-header *ngIf="population; else loading">-->
<!--      <mat-card-subtitle>{{population.speciesName}}</mat-card-subtitle>-->
<!--      <mat-card-title>{{population.displayName}}</mat-card-title>-->
<!--    </mat-card-header>-->
<!--    <mat-card-content *ngIf="population">-->
<!--      <mat-nav-list>-->
<!--        <mat-list-item [routerLink]="getLink('settings')" >Population Settings</mat-list-item>-->
<!--        <mat-list-item [routerLink]="getLink('contributors')">Population Supporters</mat-list-item>-->
<!--        <mat-list-item [routerLink]="getLink('members')">Population Members</mat-list-item>-->
<!--        <mat-list-item [routerLink]="getLink('individuals')">Population Individuals</mat-list-item>-->
<!--        <mat-list-item [routerLink]="getLink('requests')">Population Membership Requests</mat-list-item>-->
<!--        <mat-list-item [routerLink]="getLink('data')">Access Population Data</mat-list-item>-->
<!--      </mat-nav-list>-->

<!--      <mat-divider></mat-divider>-->
<!--    </mat-card-content>-->

<!--    <mat-card-footer>-->
<!--      <mat-progress-bar mode="indeterminate" *ngIf="!loading" color="accent"></mat-progress-bar>-->
<!--    </mat-card-footer>-->
<!--  </mat-card>-->

<!--  <app-population-settings-component [population]="population" [canLoad]="true"></app-population-settings-component>-->
<!--  <mat-divider></mat-divider>-->
<!--  -->
<!--  <mat-divider></mat-divider>-->
<!--&lt;!&ndash;  &ndash;&gt;-->
<!--</div>-->


<ng-template #loading>
  <app-loading-overlay [criteria]="!population" [title]="'Loading Population Settings...'"></app-loading-overlay>
</ng-template>

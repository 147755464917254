<mat-drawer-container class="editing-sidebar">
  <mat-drawer mode="over" #drawer position="end" class="editing-sidebar-drawer-content top-drawer">
    <ng-container *ngIf="activeModule === 'newRequest'">
      <ng-container *ngTemplateOutlet="newRequestContent"></ng-container>
    </ng-container>

    <!--    <ng-container *ngIf="activeModule === 'updatingBehavior'">-->
    <!--      <ng-container *ngTemplateOutlet="behaviorUpdateContent"></ng-container>-->
    <!--    </ng-container>-->
  </mat-drawer>
  <mat-drawer-content>
    <div class="content-container">
      <div class="content-header">
        <button mat-button (click)="startExportRequest()"><mat-icon>add</mat-icon>Create Request</button>
        <button mat-button (click)="refresh()" *ngIf="!refreshing"><mat-icon>refresh</mat-icon>Refresh</button>
        <span *ngIf="refreshing">
          <mat-progress-spinner color="accent" diameter="25"></mat-progress-spinner>
        </span>
      </div>
      <mat-divider></mat-divider>
      <table mat-table [dataSource]="jobs" class="mat-elevation-z8">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Date Submitted </th>
          <td mat-cell *matCellDef="let job"> {{ job.submissionDate }} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let job">
            <a (click)="review(job)">{{ job.options.name }}</a>
          </td>
        </ng-container>


        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef> Start Date </th>
          <td mat-cell *matCellDef="let job"> {{ date.formatDateFromAny(job.startDate, false, true, true, true) }} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef> End Date </th>
          <td mat-cell *matCellDef="let job"> {{ date.formatDateFromAny(job.endDate, false, true, true, true)  }} </td>
        </ng-container>

        <ng-container matColumnDef="requestingUser">
          <th mat-header-cell *matHeaderCellDef> Requesting User </th>
          <td mat-cell *matCellDef="let job"> {{ job.requestingUser }} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let job"> {{ job.status }} </td>
        </ng-container>

        <ng-container matColumnDef="jobType">
          <th mat-header-cell *matHeaderCellDef> Job Type </th>
          <td mat-cell *matCellDef="let job"> {{ job.jobType }} </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef> Options </th>
          <td mat-cell *matCellDef="let job"> {{ job.options }} </td>
        </ng-container>


        <ng-container matColumnDef="fetch">
          <th mat-header-cell *matHeaderCellDef> Fetch Result</th>
          <td mat-cell *matCellDef="let job">
            <span *ngIf="(job.status == 'Pending' || job.status == 'New')  && !job.loading">
              <mat-icon matTooltip="Job is still processing">hourglass_empty</mat-icon>
            </span>
            <button mat-icon-button *ngIf="job.status == 'Finished' && !job.loading" (click)="fetchResults(job)"><mat-icon>download</mat-icon></button>
            <span *ngIf="job.status == 'Failed' && !job.loading">
              <mat-icon matTooltip="Job could not be completed">feedback</mat-icon>
            </span>
            <span *ngIf="job.loading">
              <mat-progress-spinner mode="indeterminate" diameter="25" color="accent"></mat-progress-spinner>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Delete Request </th>
          <td mat-cell *matCellDef="let job">
            <button mat-icon-button (click)="deleteJob(job)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-drawer-content>
</mat-drawer-container>


<ng-template #newRequestContent>

<div>
  <h2>Creating a new job to generate a social network graph</h2>
  <form [formGroup]="myForm" (ngSubmit)="submit()">
    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startPicker" formControlName="startDate" [disabled]="true">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endPicker" formControlName="endDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" [disabled]="true">
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Colormap (cmap)</mat-label>
        <mat-select formControlName="cmap">
          <mat-option *ngFor="let cmap of colormaps" [value]="cmap">{{ cmap }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Width</mat-label>
        <input matInput type="number" formControlName="width">
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Height</mat-label>
        <input matInput type="number" formControlName="height">
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Inner Scale</mat-label>
        <input matInput type="number" formControlName="inner_scale">
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Outer Scale</mat-label>
        <input matInput type="number" formControlName="outer_scale">
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Node Size</mat-label>
        <input matInput type="number" formControlName="node_size">
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Node Alpha</mat-label>
        <input matInput type="number" step="0.1" min="0" max="1" formControlName="node_alpha">
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Edge Alpha</mat-label>
        <input matInput type="number" step="0.1" min="0" max="1" formControlName="edge_alpha">
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Label Size</mat-label>
        <input matInput type="number" formControlName="label_size">
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field  color="accent">
        <mat-label>Image Format</mat-label>
        <mat-label>Image Format</mat-label>
        <mat-select formControlName="img_format">
          <mat-option *ngFor="let fmt of fileExtensions" [value]="fmt">{{ fmt }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </form>

    <div class="button-row">
      <button mat-button (click)="drawer.close()">Cancel</button>
      <button mat-button (click)="submit()" [disabled]="myForm.invalid" *ngIf="!submitting || (request !== undefined && !request.disabled)">Submit Request</button>
      <span *ngIf="submitting">
              <mat-progress-spinner mode="indeterminate" diameter="25" color="accent"></mat-progress-spinner>
      </span>
    </div>

  </div>
</ng-template>

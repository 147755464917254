import { Injectable } from '@angular/core';
import {AnalysisJob} from "../models/analysis/analysisJob.model";
import {Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AnalysisService {
  private apiUrl = `${environment.server.baseUrl}/api/analysis`;

  constructor(private http: HttpClient) {}

  getAllJobs(populationId: string): Observable<AnalysisJob[]> {

    return this.http.get<AnalysisJob[]>(`${this.apiUrl}/population/${populationId}`);
  }

  getJob(id: string): Observable<AnalysisJob> {
    return this.http.get<AnalysisJob>(`${this.apiUrl}/${id}`);
  }

  createJob(job: AnalysisJob): Observable<AnalysisJob> {
    return this.http.post<AnalysisJob>(this.apiUrl, job);
  }

  updateJob(id: string, job: AnalysisJob): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/${id}`, job);
  }

  deleteJob(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  fetchResult(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/results/${id}`);
  }
}

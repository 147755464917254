<mat-drawer-container class="editing-sidebar">
  <mat-drawer mode="over" #drawer position="end" class="editing-sidebar-drawer-content">
    <div class="editing-sidebar-drawer-content" *ngIf="activeElement">
      <h3>Set Value for {{activeElement.name}}</h3>
      <h6>Settings | {{activeElement.settingsGroup.toUpperCase()}} | {{activeElement.property}}</h6>

      <h6>Information: {{activeElement.help}}</h6>
      <div *ngIf="getType(activeElement) == 'string'">
        <mat-form-field color="accent">
          <mat-label>{{activeElement.name}}</mat-label>
          <input matInput *ngIf="getType(activeElement) === 'string'" [(ngModel)]="activeElement.value" />
        </mat-form-field>
      </div>


      <div *ngIf="getType(activeElement) ==='bool'">
        <mat-button-toggle-group [(ngModel)]="activeElement.value">
          <mat-button-toggle [value]="false">False</mat-button-toggle>
          <mat-button-toggle [value]="true">True</mat-button-toggle>
        </mat-button-toggle-group>
<!--        <mat-checkbox [(ngModel)]="activeElement.value" color="accent">{{activeElement.name}}</mat-checkbox>-->
      </div>

      <div class="button-row">
        <button mat-button (click)="cancelEdit()">Cancel</button>
        <button mat-button (click)="save(activeElement)">Save</button>
      </div>
    </div>

<!--    <ng-container *ngIf="activeModule === 'addingPrey'">-->
<!--      <ng-container *ngTemplateOutlet="preyAddContent"></ng-container>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="activeModule === 'updatingPrey'">-->
<!--      <ng-container *ngTemplateOutlet="preyUpdateContent"></ng-container>-->
<!--    </ng-container>-->
  </mat-drawer>
  <mat-drawer-content>
    <div class="table-block">
      <h4>Main Settings</h4>
      <table mat-table [dataSource]="mainSettingsDataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
           <a (click)="startEdit(element)">{{element.name}} </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>
        <ng-container matColumnDef="help">
          <th mat-header-cell *matHeaderCellDef>Information</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matTooltip]="element.help"><mat-icon>info</mat-icon></button>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>Delete</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="deleteTarget(element)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-divider></mat-divider>
    <div class="table-block">
      <h4>Location Settings</h4>
      <table mat-table [dataSource]="locationSettingsDataSource">
        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <a (click)="startEdit(element)">{{element.name}} </a></td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>
        <ng-container matColumnDef="help">
          <th mat-header-cell *matHeaderCellDef>Information</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matTooltip]="element.help"><mat-icon>info</mat-icon></button>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>Delete</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="deleteTarget(element)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <mat-divider></mat-divider>
    <div class="table-block">
      <h4>Submission Settings</h4>
      <table mat-table [dataSource]="submissionSettingsDataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <a (click)="startEdit(element)">{{element.name}} </a></td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>
        <ng-container matColumnDef="help">
          <th mat-header-cell *matHeaderCellDef>Information</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matTooltip]="element.help"><mat-icon>info</mat-icon></button>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>Delete</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="deleteTarget(element)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  </mat-drawer-content>
</mat-drawer-container>



<div class="submission-limitation" *ngIf="licenses">
  <h2 matTooltip="You can limit the extent to which your data can be seen" [matTooltipPosition]="'before'">Data Limitation</h2>
  <mat-radio-group [(ngModel)]="dataLimitation" (ngModelChange)="update()" class="flex-vertical">
    <mat-radio-button *ngFor="let license of licenses" [value]="license" [disabled]="isDisabled(license)" [matTooltip]="license.description!" matTooltipPosition="before" class="full-width license-option">
      <div class="submission-license">
        <span>{{license.name}}</span>
        <div class="license-logos">
          <span class="license-logo" *ngFor="let logo of getLicenseLogos(license)">
            <a [href]="logo.link" target="_blank" >
                <img [src]="logo.img" [matTooltip]="logo.text"  [alt]="logo.text"/>
            </a>
          </span>
        </div>
      </div>

    </mat-radio-button>
  </mat-radio-group>
</div>

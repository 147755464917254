import {Component, OnInit, ViewChild} from '@angular/core';
import {AnimalService} from "../../../../services/animal/animal.service";
import {AnimalDto} from "../../../../models/dto/animal/animalDto";
import {ActivatedRoute} from "@angular/router";
import {MatSort} from '@angular/material/sort';
import {ResponseDto} from "../../../../models/dto/response/responseDto";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../services/error/error-handler.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MatDrawer} from "@angular/material/sidenav";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {DateService} from "../../../../services/utilities/date.service";
import {AnimalProfileDto} from "../../../../models/dto/animal/animalProfileDto";
import {UuidService} from "../../../../services/utilities/uuid.service";

@Component({
  selector: 'app-population-individuals',
  templateUrl: './population-individuals.component.html',
  styleUrls: ['./population-individuals.component.scss'],
})
export class PopulationIndividualsComponent implements OnInit {

  public animals?: Array<AnimalDto>
  private populationId?: string;
  public displayedColumns: Array<string> = [
    'id',
    'identifier',
    'alternateIdentifiers',
    'sex',
    'birthYear',
    'deathYear',
    'delete'
  ]
  public dataSource: any;

  @ViewChild(MatPaginator, {static:false}) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatDrawer) drawer!: MatDrawer;

  public activeElement?: AnimalDto;
  activeModule?: string;
  years: number[] = [];
  form!: FormGroup;
  ngAfterViewInit() {

  }

  constructor(
    private animalService: AnimalService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private log: ILoggingService,
    public dateService: DateService,
    private fb: FormBuilder,
    private uuid: UuidService,
  ) {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 1900; year--) {
      this.years.push(year);
    }
  }

  ngOnInit() {

    this.route.params.subscribe(res => {
      if (res["id"] !== undefined) {
        this.populationId = res["id"];
        this.animalService.getAnimalPreviews(res["id"], "all", true)
          .subscribe( res => {


          for (let animal of res) {
            animal.primaryIdentifier = animal.identifier.split(" / ")[0];
            // animal.alternateIdElements = animal.alternateIds?.map(a => {
            //   return {id: animal.id, identifier: a}});
            if (animal.dateOfBirth) {
              animal.dateOfBirth = new Date(animal.dateOfBirth);
              animal.birthYear = animal.dateOfBirth.getFullYear();
              animal.birthMonth = animal.dateOfBirth.getMonth();
            }
            if (animal.dateOfDeath) {
              animal.dateOfDeath = new Date(animal.dateOfDeath);
              animal.deathYear = animal.dateOfDeath.getFullYear();
              animal.deathMonth = animal.dateOfDeath.getMonth();
            }
          }
          this.animals = res;
          this.dataSource = new MatTableDataSource<AnimalDto>(this.animals)
          this.dataSource.paginator = this.paginator;
          this.dataSource.data = this.animals;
          this.dataSource.sort = this.sort;
          this.paginator.length = this.animals.length;
          this.dataSource.filterPredicate = (data: AnimalDto, filter: string) =>
            data.identifier.trim().toLowerCase().includes(filter) || data.alternateIds?.join('/').trim().toLowerCase().includes(filter);
        })
      }
    })

  }


  save(animal: AnimalDto) {
      animal.alternateIds = this.alternateIds.value.map((group: any) => group.value);
      animal.editing = false;
      animal.identifier = animal.primaryIdentifier;
      this.setIds(animal)
      this.setDates(animal);
      if (animal.new) {
        this.create(animal)
      } else {
        this.update(animal)
      }
      this.drawer.close();
      this.ngOnInit();
  }

  create(animal: AnimalDto) {
    this.animalService.createAnimal(animal).subscribe({
      next: (value: AnimalDto) => {
        this.log.info(`${value.identifier} has been created`)
      }, error: (value: HttpErrorResponse) => {
        this.log.info(`${animal.primaryIdentifier} could not be created (${value.message})`);
      }
    })
  }

  update(animal: AnimalDto) {
    this.animalService.updateAnimalDetails(animal).subscribe({
      next: (value: AnimalProfileDto) => {
        this.log.info(`${value.identifier} has been updated`)
      }, error: (value: HttpErrorResponse) => {
        this.log.info(`${animal.primaryIdentifier} could not be updated (${value.message})`);
      }
    })
  }

  private setIds(animal: AnimalDto) {

  }

  private setDates(animal: AnimalDto) {
    if (animal.birthYear !== undefined && animal.birthMonth !== undefined) {
      animal.dateOfBirth = new Date(animal.birthYear, animal.birthMonth, 2);
    }
    if (animal.deathYear !== undefined && animal.deathMonth !== undefined) {
      animal.dateOfDeath = new Date(animal.deathYear, animal.deathMonth, 2);
    }
  }

  addAlternateId(animal: AnimalDto) {
    if (animal.alternateIdElements === undefined) {
      animal.alternateIdElements = new Array();
    }
    animal.alternateIdElements.push({id: animal.id, identifier: ""})

  }

  deleteAnimal(animal: AnimalDto) {
    this.animalService.deleteAnimal(animal.id).subscribe({
    next: (value: ResponseDto) => {
      if (value.successful) {
        this.log.info(`Successfully deleted ${animal.identifier}`);
        const idx = this.animals?.findIndex(a => a.id == animal.id);
        if (idx !== undefined) {
          this.animals?.splice(idx, 1);
        }
      } else {
        this.log.error(`Could not delete ${animal.identifier}: ${value.errorMessages.join(', ')}`);
      }
    },
      error: (value: HttpErrorResponse) => {
      this.errorHandler.handleRequestError(`Deleting ${animal.identifier}`, value);
      }
    })
  }

  getProfileLink(animal: AnimalDto) {
    return `/individuals/${animal.id}`;
  }

  doUpdate(element: AnimalDto) {
    this.activeElement = element;
    this.initializeForm();
    this.activeModule = 'updateAnimal';
    this.drawer.open();
  }

  initializeForm(): void {
    if (!this.activeElement) return;
    if (this.activeElement.alternateIds == undefined) {
      this.activeElement.alternateIds = [];
    }
    // Initialize the form and populate the alternateIds FormArray from activeElement.alternateIds
    this.form = this.fb.group({
      alternateIds: this.fb.array(
        this.activeElement.alternateIds.map((id) => this.fb.group({ value: id }))
      ),
    });
  }

  get alternateIds(): FormArray {
    return this.form.get('alternateIds') as FormArray;
  }

  addId(): void {
    // Add a new empty ID to the array
    this.alternateIds.push(this.fb.group({ value: '' }));
  }

  removeId(index: number): void {
    // Remove the ID at the specified index
    this.alternateIds.removeAt(index);
  }

  doStartNew() {
    const newIndividual = {
      identifier: "",
      primaryIdentifier: "",
      populationId: this.populationId,
      id: this.uuid.generate(),
      new: true
    }
    this.doUpdate(newIndividual)
  }

  applyFilter($event: KeyboardEvent) {

    this.dataSource.filter = ($event.target as HTMLInputElement).value.trim().toLowerCase();
  }
}

<div mat-dialog-title>
  Finalize Your Encounter
</div>
<div mat-dialog-content>
  <mat-stepper orientation="vertical" linear>
    <mat-step editable="true" (interacted)="updateNominal(true)">
        <ng-template matStepLabel>Nominal Coordinates</ng-template>
          <mat-checkbox [(ngModel)]="data.encounter.location!.nominal">Is the location you've chosen exact?</mat-checkbox>

        <div>
          <button mat-stroked-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step editable="true" (interacted)="updateComplete(true)">
        <ng-template matStepLabel>All Accounted For</ng-template>
        <h4>Are there images of each individual present in the encounter?</h4>
        <mat-radio-group class="stacked" [(ngModel)]="data.encounter.completeStatus" [ngModelOptions]="{standalone: true}">
          <mat-radio-button value="complete" >Yes</mat-radio-button>
          <mat-radio-button value="incomplete">No</mat-radio-button>
          <mat-radio-button value="unknown">Unknown</mat-radio-button>
        </mat-radio-group>

        <div>
          <button mat-stroked-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step editable="true" (interacted)="updateLicenseSet(true)">
      <form [formGroup]="thirdFormGroup">
        <ng-template matStepLabel>License Information</ng-template>
        <h3>Answer these questions to choose how your data can or will be used.</h3>

        <div class="question">
          <div class="question-content" matTooltip="If yes, your data will be protected by a creative commons non commercial
use license. If no, your data will only be visible to users within your
organization." matTooltipPosition="above">
            <div class="question-number">1.</div>
            <div class="question-text">By submitting I understand that my data can be publicly viewed </div>
          </div>
          <div class="question-response">
            <mat-radio-group class="stacked" [(ngModel)]="public" color="accent" (ngModelChange)="updateLicense()"  [ngModelOptions]="{standalone: true}">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="question" *ngIf="public">
          <div class="question-content"
               matTooltip="If no, your data will be protected by a creative commons non-commercial use license and may be used to benefit conservation and understanding of this population by scientists, students or educators without contacting or crediting you. If yes, you will be contacted with any requests that include use of your data."
               matTooltipPosition="above">
            <div class="question-number">2.</div>
            <div class="question-text">Please contact me with any requests that include the use of my data. </div>
          </div>
          <div class="question-response">
            <mat-radio-group class="stacked"  [(ngModel)]="attribute" color="accent"  (ngModelChange)="updateLicense()" [ngModelOptions]="{standalone: true}">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="error-messages" *ngIf="!validSelection">
          <mat-error *ngFor="let err of errorMessages">{{err}}</mat-error>
        </div>
        <mat-divider></mat-divider>
        <div class="license-info" *ngIf="license !== undefined && licenseLogo !== undefined && validSelection">
          <h3>Your License:</h3>

          <div class="license-content submission-license" [matTooltip]="licenseTooltip" matTooltipPosition="before">
            <p class="license-name"><b>{{license.name}}</b></p>
            <div class="license-logos">
              <div class="license-logo" *ngFor="let logo of licenseLogo">
                <a [href]="logo.link" target="_blank" >
                  <img [src]="logo.img" [matTooltip]="logo.text"  [alt]="logo.text"/>
                </a>
              </div>
            </div>
          </div>
          <p>{{license.description}}</p>
        </div>

        <div>
          <button mat-stroked-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Review Your Selections</ng-template>
      <div class="overview">
        <mat-list>
          <mat-list-item *ngIf="data.encounter.location?.nominal"><span class="overview-detail"><mat-icon>check</mat-icon> You have chosen the exact location of the encounter</span></mat-list-item>
          <mat-list-item *ngIf="!data.encounter.location?.nominal"><span class="overview-detail"><mat-icon>check</mat-icon> You have chosen an approximate location for the encounter</span></mat-list-item>
          <mat-list-item *ngIf="data.encounter.completeStatus === 'complete'"><span class="overview-detail"><mat-icon>check</mat-icon> You are submitting images of all individuals present during the encounter</span></mat-list-item>
          <mat-list-item *ngIf="data.encounter.completeStatus === 'incomplete'"><span class="overview-detail"><mat-icon>check</mat-icon> You are not submitting images of all individuals present during the encounter</span></mat-list-item>
          <mat-list-item *ngIf="data.encounter.completeStatus === 'unknown'"><span class="overview-detail"><mat-icon>check</mat-icon> It is not clear if you are submitting images of all individuals present during the encounter</span></mat-list-item>

          <mat-list-item *ngIf="validSelection"><span class="overview-detail"><mat-icon>check</mat-icon> Your chosen license: {{license?.description}}</span></mat-list-item>
          <mat-list-item *ngIf="!validSelection && errorMessages.length > 0"><mat-error>{{errorMessages[0]}}</mat-error></mat-list-item>

        </mat-list>
      </div>
    </mat-step>
  </mat-stepper>



</div>
<div class="button-row" mat-dialog-actions>
  <button mat-stroked-button color="accent" [mat-dialog-close]="dataLimitation" [disabled]="!validSubmission">Confirm Details</button>
  <button mat-stroked-button (click)="onNoClick()" >Cancel</button>
</div>

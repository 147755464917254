import {Component, Input, OnInit} from '@angular/core';
import {OrganizationDto} from "../../../../../models/dto/user/organization/organizationDto";
import {Observable} from "rxjs";
import {
  OrganizationCreationDialogComponent
} from "../../organizations/organization-creation-dialog/organization-creation-dialog.component";
import {UserManagementService} from "../../../../../services/user/management/user-management.service";
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {UserOrganizationService} from "../../../../../services/user/organization/user-organization.service";
import {OrganizationCreationDto} from "../../../../../models/dto/organization/organizationCreationDto";
import {CreateOrganizationResponseDto} from "../../../../../models/dto/user/organization/createOrganizationDto";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";

@Component({
  selector: 'app-organization-administration',
  templateUrl: './organization-administration.component.html',
  styleUrls: ['./organization-administration.component.scss']
})
export class OrganizationAdministrationComponent implements OnInit {

  @Input() organizations: Observable<Array<OrganizationDto>> | undefined;

  displayedColumns: string[] = ['name'];
  dataSource: Array<OrganizationDto> = [];
  constructor(
    private dialog: MatDialog,
    private userService: UserManagementService,
    private orgService: UserOrganizationService,
    private log: ILoggingService
  ) { }

  ngOnInit(): void {
    if (this.organizations) {
      this.organizations.subscribe(res => {
        this.dataSource = res.filter(a => a.name.toLowerCase() !== 'public');
      })
    }
  }

  getLink(org: OrganizationDto) {
    return `/administration/organizations/${org.id}`;
  }

  createOrganization() {
    const ref = this.dialog.open(OrganizationCreationDialogComponent,
      {data:
          {name: "", allUsers: this.userService.getUserDtos(), users: new Array<UserProfileDto>()},
      width: "500px"})
    ref.afterClosed().subscribe(res => {
      if (!res || !res.name || !res.users) return;
      const dto: OrganizationCreationDto = {users: res.users, name: res.name, researchOrganization: res.researchOrganization};

      this.orgService.createNewOrganization(dto).subscribe({
        next: (value: CreateOrganizationResponseDto) => {
          this.log.info(`${dto.name} created!`)
          this.organizations = this.orgService.getUserOrganizations();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not create organization: ${value.message}`)
        }
      })
    })
  }
}

<h1 mat-dialog-title *ngIf="!createSelected">Join or Create A New Population</h1>
<h1 mat-dialog-title *ngIf="createSelected">Create a New Population</h1>
<div mat-dialog-content>
  <div *ngIf="joining">
<!--    <h4>Join an Known Population</h4>-->
    <mat-form-field color="accent" class="full-width">
      <mat-select [formControl]="populationControl" placeholder="Choose a population...">
        <mat-option *ngFor="let population of populations" [value]="population">{{population.displayName}}</mat-option>
        <mat-option *ngIf="populations === undefined || populations.length === 0" [value]="undefined">None found</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <button class="action-button" *ngIf="!joining && !createSelected" mat-raised-button color="accent" (click)="joining = !joining">Join a Known Population</button>


  <div *ngIf="!joining && createSelected">
    <span class="population-creation-section"><b>All population creation applications are submitted by the administrators for peer review before being authorized.</b></span>
    <h2>Population Details</h2>
    <div class="population-creation-section">
      <mat-form-field color="accent">
        <mat-label>Species Name</mat-label>
        <input matInput placeholder="Orcinus orca"  [(ngModel)]="data.speciesName" required/>
      </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <div class="population-creation-section">
      <mat-form-field color="accent">
        <mat-label>Display Name</mat-label>
        <input matInput placeholder="Transient Killer Whale"  [(ngModel)]="data.displayName" required/>
      </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <div class="pop-location-selection population-creation-section">
      <h2 matTooltip="The area where the population is mainly located. Will be used to simplify the data submission process.">Population Location</h2>
      <button mat-stroked-button color=accent (click)="viewMap()">Select Population Center</button>
      <div class="pop-location" *ngIf="popLat !== undefined && popLng !== undefined">
        <h3>Population Center at: </h3>
        <p>Latitude: {{popLat}}</p>
        <p>Longitude: {{popLng}}</p>
        <mat-form-field color="accent">
          <mat-label>Location Name</mat-label>
          <input matInput [(ngModel)]="data.location!.name" required />
        </mat-form-field>
      </div>

    </div>
    <mat-divider></mat-divider>
    <div class="population-creation-section">
      <h2 matTooltip="Two population authorities are necessary in order to create a population.">Population Authorities</h2>
      <h4>In order to maintain data integrity, we will contact the following people in order to ensure that <b>you</b> are the correct person to be managing this population</h4>
      <div class="population-authorities">
        <h3>Authority 1</h3>
        <div class="population-authority">

          <mat-form-field color="accent" >
            <mat-label>First Name</mat-label>
            <input matInput [(ngModel)]="data.authorityA!.firstName" required/>
          </mat-form-field>
          <mat-form-field color="accent">
            <mat-label>Last Name</mat-label>
            <input matInput [(ngModel)]="data.authorityA!.lastName" required/>
          </mat-form-field>
          <mat-form-field color="accent">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="data.authorityA!.email" required/>
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <h3>Authority 2</h3>
        <div class="population-authority">

          <mat-form-field color="accent">
            <mat-label>First Name</mat-label>
            <input matInput [(ngModel)]="data.authorityB!.firstName" required/>
          </mat-form-field>
          <mat-form-field color="accent">
            <mat-label>Last Name</mat-label>
            <input matInput [(ngModel)]="data.authorityB!.lastName" required/>
          </mat-form-field>
          <mat-form-field color="accent">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="data.authorityB!.email" required/>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="population-creation-section">
      <h2>Additional Details for Review</h2>
      <mat-form-field color="accent" class="full-width">
        <mat-label>Message to Reviewers</mat-label>
        <textarea matInput [(ngModel)]="data.message"></textarea>
      </mat-form-field>
    </div>
    <div class="errors" *ngIf="errors">
      <ul>
        <li *ngFor="let error of errors">{{error}}</li>
      </ul>
    </div>



  </div>
</div>

<div mat-dialog-actions>
  <div class="dialog-close-buttons">
    <button mat-button mat-dialog-close cdkFocusInitial color="accent">Close</button>
    <button mat-raised-button color="accent" *ngIf="!populationControl.pristine && joining" (click)="joinPopulation()">Join {{populationControl.value.displayName}}</button>
    <button mat-button *ngIf="joining && !createSelected" mat-raised-button color="accent" (click)="joining = !joining; createSelected = true">Create a New Population</button>
    <button mat-button mat-dialog-close color="accent" [matDialogClose]="data" *ngIf="createSelected" [disabled]="!applicationValid()">Send Application</button>
<!--    <button mat-button mat-dialog-close [matDialogClose]="data" *ngIf="!createSelected">Join</button>-->
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";

@Component({
  selector: 'app-population-administration',
  templateUrl: './population-administration.component.html',
  styleUrls: ['./population-administration.component.scss']
})
export class PopulationAdministrationComponent implements OnInit {

  @Input() populations: Observable<Array<PopulationDto>> | undefined;

  displayedColumns: string[] = ['displayName', 'speciesName', 'locationName'];
  dataSource: Array<PopulationDto> = [];

  constructor(private router: Router, private logService: ILoggingService) { }

  ngOnInit(): void {
    if (this.populations) {
      this.populations.subscribe(res => {
        this.dataSource = res;
      })
    }
  }

  getLink(population: PopulationDto) {
    return `/administration/populations/${population.id}`;
  }

  joinPopulation() {

  }

  goToPopulation(element: PopulationDto) {
    if (element.roleName?.toLowerCase() === 'administrator') {
      this.router.navigate([this.getLink(element)]);
    } else {
      this.logService.info(`You are not an administrator of ${element.displayName}. You cannot alter the settings.`)
    }
  }
}

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {PopulationDto} from "../../models/dto/population/populationDto";
import {BehaviorSubject, combineLatest, Observable, Subject} from "rxjs";
import {environment} from "../../environments/environment";
import {PopulationUserDto} from "../../models/dto/population/populationUserDto";
import {UserProfileDto} from "../../models/dto/user/userProfileDto";
import {PopulationRoleDto} from "../../models/dto/population/populationRoleDto";
import {ILoggingService} from "../logging/logging.service.interface";
import {AuthenticationService} from "../user/authentication.service";
import {UserContentService} from "../user/content/user-content.service";
import {PopulationSettingsDto} from "../../models/dto/population/populationSettingsDto";
import {ResponseDto} from "../../models/dto/response/responseDto";
import {PopulationPrey} from "../../models/dto/population/populationPrey";
import {PopulationFeedEntryDto} from "../../models/dto/population/populationFeedEntryDto";
import {IItemStorageService} from "../storage/item/item-storage.service.interface";
import {ContributorDto} from "../../models/dto/population/contributorDto";
import {UserCreationInvitationDto} from "../../models/dto/user/userCreationInvitationDto";
import {PopulationStatisticsDto} from "../../models/dto/population/populationStatisticsDto";
import {PopulationOverviewDto} from "../../models/dto/population/populationOverviewDto";
import {PopulationDomain} from "../../models/dto/population/populationDomain";
import {ItemResponseDto} from "../../models/dto/response/itemResponseDto";
import {JoinRequestDto} from "../../models/dto/organization/organizationJoinRequestDto";
import {RestClientService} from "../client/rest-client.service";

export interface PopulationRoleSet {
  viewer?: boolean;
  expert?: boolean;
  professional?: boolean;
  administrator?: boolean;
  populationId?: string;
  userId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PopulationService {
  private readonly STORAGEKEY = "population";
  private _population: BehaviorSubject<PopulationDto> = new BehaviorSubject({});
  private _populationRoleSet: BehaviorSubject<PopulationRoleSet> = new BehaviorSubject<PopulationRoleSet>({});
  private _populationSettings: BehaviorSubject<PopulationSettingsDto> = new BehaviorSubject<PopulationSettingsDto>({});
  private _populations: BehaviorSubject<Array<PopulationDto>> = new BehaviorSubject<Array<PopulationDto>>([]);
  public readonly population: Observable<PopulationDto> = this._population.asObservable();
  public readonly populations: Observable<Array<PopulationDto>> = this._populations.asObservable();
  public readonly populationRoles: Observable<PopulationRoleSet> = this._populationRoleSet.asObservable();
  public readonly populationSettings: Observable<PopulationSettingsDto> = this._populationSettings.asObservable();


  constructor(
    private httpClient: HttpClient,
    private restClient: RestClientService
    ) {}

  public getPopulationById(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}/${id}`;
    return this.httpClient.get<PopulationDto>(url);
  }


  public getPopulationJoinRequestRequired(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}/join/permission?populationId=${id}`;
    return this.httpClient.get<ItemResponseDto<boolean>>(url);
  }

  getPopulationJoinRequests(populationId: string): Observable<ItemResponseDto<Array<JoinRequestDto>>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}/requests/?populationId=${populationId}`;
    return this.httpClient.get<ItemResponseDto<Array<JoinRequestDto>>>(url);
  }

  respondToPopulationJoinRequest(request: JoinRequestDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}/requests`;
    return this.httpClient.post<ResponseDto>(url, request);
  }

  submitJoinRequest(res: JoinRequestDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}/requests`;
    return this.httpClient.patch<ResponseDto>(url, res);

  }


  private setPopulationRights(population: PopulationDto) {
    if (population !== undefined && population.id !== undefined) {
      this.getPopulationRights(population.id)
        .subscribe(res => {
          this._populationRoleSet.next(res);
        })
    }
  }

  private setPopulationSettings(population: PopulationDto) {
    // if (population !== undefined && population.id !== undefined) {
    //   this.getPopulationSettings(population.id)
    //     .subscribe(res => {
    //       this._populationSettings.next(res);
    //     })
    // }
  }

  removeMember(populationId: string, userId: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}/${populationId}/${userId}`;
    return this.httpClient.delete<PopulationDto>(url);
  }

  inviteMember(invitation: UserCreationInvitationDto): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.inviteUser}`;
    return this.httpClient.post<any>(url, invitation);
  }

  getPopulationRights(populationId: string): Observable<PopulationRoleSet> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulationRoles}/${populationId}`;
    return this.httpClient.get<PopulationRoleSet>(url);
  }

  getPopulations(): Observable<Array<PopulationDto>> {
    // return this.getDummyPopulations();
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}`;
    return this.httpClient.get<Array<PopulationDto>>(url);
  }

  getPopulationUsers(populationId: string): Observable<Array<PopulationUserDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulationUsers}/${populationId}/users`;
    return this.httpClient.get<Array<PopulationUserDto>>(url);
  }

  getPopulationPhotographers(populationId: string): Observable<Array<UserProfileDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.photographers}/${populationId}`;
    return this.httpClient.get<Array<UserProfileDto>>(url);
  }

  getUserPopulations(): Observable<Array<PopulationDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getUserPopulations}`;
    return this.restClient.get<Array<PopulationDto>>(url);
  }

  getUserRightedPopulations(): Observable<Array<PopulationDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getUserRightedPopulations}`;
    return this.httpClient.get<Array<PopulationDto>>(url);
  }

  getUserPopulationDomains(): Observable<Array<PopulationDomain>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getUserPopulationDomains}`;
    return this.httpClient.get<Array<PopulationDomain>>(url);
  }

  getUserNonPopulations(): Observable<Array<PopulationDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getUserNonPopulations}`;
    return this.httpClient.get<Array<PopulationDto>>(url);
  }

  createNewPopulation(population: PopulationDto): Observable<PopulationDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.createPopulation}`;
    return this.httpClient.post<PopulationDto>(url, population);
  }

  joinPopulation(population: PopulationDto): Observable<PopulationDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.joinPopulation}/${population.id}`;
    return this.httpClient.post<PopulationDto>(url, population);
  }

  public isUserPopulationAdministrator(populationId: string): Observable<boolean> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.isAdministrator}/${populationId}`;
    return this.httpClient.get<boolean>(url);
  }

  public isUserPopulationProfessional(populationId: string): Observable<boolean> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.isProfessional}/${populationId}`;
    return this.httpClient.get<boolean>(url);
  }

  public confirmPopulationMember(user: UserProfileDto, population: PopulationDto): Observable<PopulationDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.confirmUser}/${population.id}/${user.id}`;
    return this.httpClient.post<PopulationDto>(url, population);
  }
  public getUserAssignableRoles(populationId: string): Observable<Array<PopulationRoleDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.userAssignableRoles}/${populationId}`;
    return this.httpClient.get<Array<PopulationRoleDto>>(url);
  }

  public updatePopulationUserRole(member: PopulationUserDto, roleDto: PopulationRoleDto): Observable<PopulationRoleDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.updateUserRole}/${member.populationDto?.id}/${member.userProfileDto?.id}`;
    return this.httpClient.put<PopulationRoleDto>(url, roleDto);
  }

  public getPopulationSettings(populationId: string): Observable<PopulationSettingsDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.settings}/${populationId}`;
    return this.httpClient.get<PopulationSettingsDto>(url);
  }

  public updatePopulationDetails(populationId: string, population: PopulationDto): Observable<PopulationDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}/${populationId}`;
    return this.httpClient.put<PopulationDto>(url, population);
  }

  public updatePopulationSettings(populationId: string, settings: PopulationSettingsDto): Observable<PopulationSettingsDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.settings}/${populationId}`;
    return this.httpClient.put<PopulationSettingsDto>(url, settings);
  }

  public addPopulationPrey(populationId: string, prey: PopulationPrey): Observable<PopulationPrey> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.prey}/${populationId}`;
    return this.httpClient.post(url, prey);
  }

  public updatePopulationPrey(populationId: string, prey: PopulationPrey): Observable<PopulationPrey> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.prey}/${populationId}`;
    return this.httpClient.put(url, prey);
  }
  public deletePopulationPrey(populationId: string, preyId: string): Observable<PopulationPrey> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.prey}/${populationId}/${preyId}`;
    return this.httpClient.delete(url);
  }
  public getPopulationPrey(populationId: string): Observable<Array<PopulationPrey>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.prey}/${populationId}`;
    return this.httpClient.get<Array<PopulationPrey>>(url);
  }
  public getPopulationOverview(populationId: string): Observable<PopulationOverviewDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}/overview/${populationId}`;
    return this.httpClient.get<PopulationOverviewDto>(url);
  }

  public getPopulationOverviews(): Observable<Array<PopulationOverviewDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.getPopulations}/overviews`;
    return this.httpClient.get<Array<PopulationOverviewDto>>(url);
  }


  // Contributors ---------- START
  public getContributors(populationId: string): Observable<Array<ContributorDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.contributors}/${populationId}`;
    return this.httpClient.get<Array<ContributorDto>>(url);
  }

  public getContributor(populationId: string, contributorId: string): Observable<ContributorDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.contributors}/${populationId}/${contributorId}`;
    return this.httpClient.get<ContributorDto>(url);
  }

  public addContributor(populationId: string, contributor: ContributorDto): Observable<ContributorDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.contributors}/${populationId}`;
    return this.httpClient.post<ContributorDto>(url, contributor);
  }

  public updateContributor(populationId: string, contributor: ContributorDto): Observable<ContributorDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.contributors}/${populationId}`;
    return this.httpClient.put<ContributorDto>(url, contributor);
  }

  public deleteContributor(populationId: string, contributorId: string): Observable<ContributorDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.contributors}/${populationId}/${contributorId}`;
    return this.httpClient.delete<ContributorDto>(url);
  }
  // Contributors ---------- END

  public getStatistics(populationId: string): Observable<PopulationStatisticsDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.statistics}/${populationId}`;
    return this.httpClient.get<PopulationStatisticsDto>(url);
  }


  public isUserAboveExpert(populationId: string): Observable<boolean>{
    return combineLatest(
      this.isUserPopulationAdministrator(populationId),
      this.isUserPopulationProfessional(populationId),
      (a, b) => a || b);
  }

  getPopulation(): Observable<PopulationDto> {
    return this._population.asObservable();
  }


  select(population: PopulationDto): Observable<void> {
    return new Observable(observer => {
      this._population.next(population);
      observer.next();
      observer.complete();
    });
  }
 // select(population: PopulationDto) {//: Observable<PopulationDto> {
    //this._population.next(population);

    //return this._population.asObservable();

    // this.setPopulationRights(population);
    // this.setPopulationSettings(population);
    // this.getUserPopulations().subscribe({
    //   next: (res: Array<PopulationDto>) => {
    //     this._populations.next(res);
    //   },
    //   error: (error: any) => {
    //     this.log.error(`Error fetching populations for user: ${error}`)
    //   }
    // });

  //}


}

import {Component, OnInit, ViewChild} from '@angular/core';
import {PopulationDto} from "../../../../../../../models/dto/population/populationDto";
import {HttpErrorResponse} from "@angular/common/http";
import {MatTabGroup} from "@angular/material/tabs";
import {ActivatedRoute, Router} from "@angular/router";
import {PopulationService} from "../../../../../../../services/population/population.service";
import {ILoggingService} from "../../../../../../../services/logging/logging.service.interface";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-population-analysis',
  templateUrl: './population-analysis.component.html',
  styleUrls: ['./population-analysis.component.scss']
})
export class PopulationAnalysisComponent implements OnInit {

  public population?: PopulationDto

  public loading = false;
  public id?: string;
  public params: any;
  @ViewChild(MatTabGroup) tabs!: MatTabGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private populationService: PopulationService,
    private log: ILoggingService
  ) {
  }

  ngOnInit() {

    this.route.params.subscribe({
      next: (value: any) => {
        if (value["id"]) {
          this.id = value["id"]
          this.loading = true;
          this.loadPopulation();
        }

      }
    })


  }

  private loadPopulation() {
    if (!this.id) {
      this.loading = false;
      return;
    }
  this.populationService.getPopulationById(this.id).subscribe({
        next: (value:PopulationDto) => {
          this.population = value;
          this.loading = false;
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not load population: ${value.message};`)
          this.loading = false;
        }
      })
  }

  public activeTab = 0;

  setActiveTab($event: number) {
    this.activeTab = $event;
  }
}

<div mat-dialog-title>
  <h2>{{data.title}}</h2>
</div>
<div mat-dialog-content>
  <h3>Affected Items</h3>
  <div class="preview-gallery" *ngIf="data.markedImages">
    <div class="preview-item" *ngFor="let item of data.markedImages">
      <img  [src]="item.metaData.thumbnail!">
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="action-selection" *ngIf="data.action === 'side-label'">
    <h3>Choose Your Side</h3>
    <mat-form-field color="accent">
      <mat-label>Side</mat-label>
      <mat-select  [(ngModel)]="data.selection">
        <mat-option [value]="'left'">Left</mat-option>
        <mat-option [value]="'right'">Right</mat-option>
        <mat-option [value]="'other'">Other (Front, Back, etc.)</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <div class="button-row">
    <button mat-button [mat-dialog-close]="data" >Confirm Selection</button>
    <button mat-button (click)="onNoClick()">Cancel</button>
  </div>

</div>

<h1 mat-dialog-title>Update Your Profile Image</h1>
<div mat-dialog-content>
  <input type="file"  class="file-upload" (change)="addProfileImage($event)">
  <div class="image-container" *ngIf="data.userImage">
    <img [src]="imageService.maybePrefix(data.userImage.image)"  />
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.userImage" cdkFocusInitial>Update</button>
</div>

import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PopulationUserDto} from "../../../../../models/dto/population/populationUserDto";
import {PopulationRoleDto} from "../../../../../models/dto/population/populationRoleDto";
import {HttpErrorResponse} from "@angular/common/http";
import {PopulationRoleSet, PopulationService} from "../../../../../services/population/population.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {DateService} from "../../../../../services/utilities/date.service";
import {
  MembershipInvitationComponent,
  NewUserInvitation
} from "../membership-invitation/membership-invitation.component";
import {UserCreationInvitationDto} from "../../../../../models/dto/user/userCreationInvitationDto";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {WorkspaceDto} from "../../../../../models/dto/workspace/workspaceDto";
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {MatDialog} from "@angular/material/dialog";
import {MatDrawer} from "@angular/material/sidenav";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {AnimalDto} from "../../../../../models/dto/animal/animalDto";

@Component({
  selector: 'app-population-members-component',
  templateUrl: './population-members-component.component.html',
  styleUrls: ['./population-members-component.component.scss']
})
export class PopulationMembersComponentComponent implements OnInit, OnChanges {

  @Input() population?: PopulationDto;
  @Input() canLoad: boolean = false;

  @ViewChild(MatDrawer) drawer!: MatDrawer;
  @ViewChild(MatPaginator, {static:false}) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public populationMembers: Array<PopulationUserDto> | undefined;
  public populationRoles: Array<PopulationRoleDto> | undefined;
  public populationRights: PopulationRoleSet | undefined;
  public workspace: WorkspaceDto | undefined;
  private user: UserProfileDto | undefined;

  public newUserInvitation: NewUserInvitation | undefined;

  activeElement?: PopulationUserDto;
  activeModule?: string;
  dataSource: any;
  displayedColumns = ['profile', 'name', 'email', 'role', 'joined', 'confirmed', 'delete']
  constructor(
    private populationService: PopulationService,
    private logService: ILoggingService,
    private dateService: DateService,
    private dialog: MatDialog,
    private workspaceService: WorkspaceService
    ) { }

  ngOnInit(): void {
    this.workspaceService.workspace.subscribe( res => {
      if (res && res.settings) {
        this.user = res.settings!.user;
      }

    })
    if (this.population && this.population.id !== undefined && this.canLoad) {
      this.populationService
        .getPopulationRights(this.population.id!)
        .subscribe({
          next: (value: PopulationRoleSet) => {
            this.populationRights = value;
            this.populationService
              .getUserAssignableRoles(this.population!.id!)
              .subscribe({
                next: (value: Array<PopulationRoleDto>) => {
                  this.populationRoles = value;
                  this.populationService
                    .getPopulationUsers(this.population!.id!)
                    .subscribe({
                      next: (value: Array<PopulationUserDto>) => {
                        this.populationMembers = value;
                        this.dataSource = new MatTableDataSource<PopulationUserDto>(this.populationMembers)
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.data = this.populationMembers;
                        this.dataSource.sort = this.sort;

                        this.paginator.length = this.populationMembers.length;
                        this.dataSource.filterPredicate = (data: PopulationUserDto, filter: string) =>
                          data.userProfileDto?.firstName?.trim().toLowerCase().includes(filter) ||
                          data.userProfileDto?.lastName?.trim().toLowerCase().includes(filter) ||
                          data.userProfileDto?.email?.trim().toLowerCase().includes(filter)
                        ;
                      }
                    })
                }
              })
          }
        })
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["canLoad"] !== undefined || changes["population"] !== undefined) {
      this.ngOnInit();
    }
  }

  updateRole(member: PopulationUserDto) {
    member.populationDto = this.population;
    const roleDto: PopulationRoleDto = {
      normalizedName: member.roleName,
      name: member.roleName,
      id: undefined,
      level: undefined
    }
    this.populationService.updatePopulationUserRole(member, roleDto).subscribe({
      next: (value: PopulationRoleDto) => {
        this.logService.info(`Updated user to ${roleDto.normalizedName}`, true);
        this.drawer.close();
      },
      error: (error: HttpErrorResponse) => {
        this.logService.error(`Could not update user to ${roleDto.normalizedName}`, true);
        this.drawer.close();
      }
    })
  }

  confirmPopulationMember(member: PopulationUserDto) {

    this.populationService.confirmPopulationMember(member.userProfileDto!, this.population!)
      .subscribe({
        next: (value: PopulationDto) => {
          this.logService.info(`Confirmed ${member.userProfileDto!.firstName} ${member.userProfileDto!.lastName}.`, true);
          member.confirmed = true;
          member.confirmedOn = new Date();
        },
        error: (error: HttpErrorResponse) => {
          this.logService.info(`Could not confirm ${member.userProfileDto!.firstName} ${member.userProfileDto!.lastName}. ${error.message}`, true);

        }
      })
  }

  getDateString(memberSince: Date) {
    return this.dateService.formatDateFromAny(memberSince);
  }

  openMemberAddition() {
    const message = `We're looking forward to your contribution!`;
    const dialogRef = this.dialog.open(MembershipInvitationComponent, {data: {user: {informUser: true}, message: message, roles: this.populationRoles, role: this.populationRoles![this.populationRoles!.length - 1].id}, width: "500px"});
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const creationDto = new UserCreationInvitationDto(res.user, res.message, this.population!.id!, res.role)
        this.populationService.inviteMember(creationDto).subscribe({
          next: (value: any) => {
            this.logService.info(`${creationDto.user.firstName} ${creationDto.user.lastName} invited to join ${this.population!.displayName}`)
          },
          error: (value: HttpErrorResponse) => {
            this.logService.error(`User could not be invited: ${value.message}`);
          }
        })
      }

    })
  }

  startEdit(element : PopulationUserDto) {
    this.activeElement = element;
    this.activeModule = 'updateRole'
    this.drawer.open();
  }

  deleteUser(element : PopulationUserDto) {
    element.populationDto = this.population;
    if (!element.populationDto?.id || !element.userProfileDto?.id) return;

    this.populationService.removeMember(element.populationDto?.id, element.userProfileDto?.id).subscribe({
      next: (value: PopulationDto) => {
        this.logService.info(`${element.userProfileDto?.firstName} ${element.userProfileDto?.lastName} removed`);
        const idx = this.populationMembers?.findIndex(a => a.userProfileDto?.id == element.userProfileDto?.id);
        if (idx !== undefined) {
          this.populationMembers?.splice(idx, 1);
        }
      }, error: (error: HttpErrorResponse) => {
        this.logService.error(`User could not be deleted: ${error.message}`);
      }
    })
  }

  sendInvite() {
    if (!this.newUserInvitation) return;
    const creationDto = new UserCreationInvitationDto(this.newUserInvitation.user, this.newUserInvitation.message, this.population!.id!, this.newUserInvitation.role)
    this.populationService.inviteMember(creationDto).subscribe({
      next: (value: any) => {
        if (!value) {
          this.logService.info(`${creationDto.user.firstName} ${creationDto.user.lastName} could not be invited to join ${this.population!.displayName}`)
          this.drawer.close();
        } else {
          this.logService.info(`${creationDto.user.firstName} ${creationDto.user.lastName} invited to join ${this.population!.displayName}`)
          this.drawer.close();
        }

      },
      error: (value: HttpErrorResponse) => {
        this.logService.error(`User could not be invited: ${value.message}`);
        this.drawer.close();
      }
    })
  }

  startAdd() {
    this.activeModule = 'inviteMember'
    const message = `We're looking forward to your contribution!`;
    this.newUserInvitation = {user: {informUser: true}, message: message, roles: this.populationRoles!, role: this.populationRoles![this.populationRoles!.length - 1].id!}
    this.drawer.open();

  }

  applyFilter($event: KeyboardEvent) {

    this.dataSource.filter = ($event.target as HTMLInputElement).value.trim().toLowerCase();
  }

  getProfileLink(element: PopulationUserDto) {
    return `/users/${element.userProfileDto!.id}`;
  }

  validString(s: string | undefined): boolean {
    return s !== undefined && s !== null && s.length > 0;
  }

  inviteDisabled(): boolean {
    if (!this.newUserInvitation) return true;
    return this.validString(this.newUserInvitation.user.firstName) && this.validString(this.newUserInvitation.user.lastName) && this.validString(this.newUserInvitation.user.email);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EncounterDto} from "../../../../../../models/dto/encounter/encounterDto";
import {EncountersService} from "../../../../../../services/encounters/encounters.service";
import {PopulationRoleSet, PopulationService} from "../../../../../../services/population/population.service";
import {UserProfileDto} from "../../../../../../models/dto/user/userProfileDto";
import {
  UpdateDetailsComponent,
  UpdateEncounterDetailsDialogData
} from "./update-details-component/update-details.component";
import {AddImagesComponent} from "./add-images/add-images.component";
import {EncounterCreationRequestDto} from "../../../../../../models/dto/encounter/encounterCreationRequestDto";
import {DateService} from "../../../../../../services/utilities/date.service";
import {ILoggingService} from "../../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {FileItem, IFileItem} from "../../../../../../models/items/files/file-item.model";
import {concat, from, mergeMap, Observable, of, Subscription, throwError, timer, toArray} from "rxjs";
import {ImageScalingService, ResizeResult} from "../../../../../../services/files/images/scale/image-scaling.service";
import {IImageService} from "../../../../../../services/files/images/image.service.interface";
import {PopulationSettingsDto} from "../../../../../../models/dto/population/populationSettingsDto";
import {DeleteEncounterDialogComponent} from "./delete-encounter-dialog/delete-encounter-dialog.component";
import {EncounterDeletionDto} from "../../../../../../models/dto/encounter/encounterDeletionDto";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ResponseDto} from "../../../../../../models/dto/response/responseDto";
import {ErrorHandlerService} from "../../../../../../services/error/error-handler.service";
import {EncounterMergeDialogComponent} from "./encounter-merge-dialog/encounter-merge-dialog.component";
import {PopulationDto} from "../../../../../../models/dto/population/populationDto";
import {environment} from "../../../../../../environments/environment";
import {Upload} from "tus-js-client";
import {FileUpload} from "../../../../submit-data/submit-data.component";
import {
  BulkItemActionConfirmationComponent
} from "./bulk-item-action-confirmation/bulk-item-action-confirmation.component";
import {concatMap} from "rxjs/operators";
import {
  SubmissionLicenseVerificationComponent
} from "../../../../submit-data/submission-license-verification/submission-license-verification.component";
import {WorkspaceService} from "../../../../../../services/workspace/workspace.service";
import {ArchivalStatusDialogComponent} from "./archival-status-dialog/archival-status-dialog.component";
 
import * as moment from 'moment';

@Component({
  selector: 'app-encounter-edit-component',
  templateUrl: './encounter-edit.component.html',
  styleUrls: ['./encounter-edit.component.scss']
})
export class EncounterEditComponent implements OnInit {
  @Input() encounter: EncounterDto | undefined;
  @Input() encounterUser: UserProfileDto | undefined;
  @Input() population: PopulationDto | undefined;
  @Input() items?: Array<IFileItem>
  @Input() populationSettings: PopulationSettingsDto | undefined;
  @Output() userChanged: EventEmitter<UserProfileDto> = new EventEmitter<UserProfileDto>();
  @Output() itemsAdded: EventEmitter<Array<FileItem>> = new EventEmitter<Array<FileItem>>();
  @Output() markingForDeletion: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() markingForSideLabel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() encounterDeleted: EventEmitter<EncounterDto> = new EventEmitter<EncounterDto>();
  @Output() reloadRequested: EventEmitter<any> = new EventEmitter<any>();

  public editing = false;
  public markForDel = false;
  public markForSideLabel = false;
  public fileItems: Array<IFileItem> | undefined;
  public files: Array<File> | undefined;
  private uploadFinished: Map<number, boolean> = new Map<number, boolean>();
  private fileCounter = 0;
  public uploadsFinished = 0;
  public populationRoles: PopulationRoleSet | undefined;
  public canEdit = false;
  public higherPrivileges = false;
  imageUploadStarted: boolean = false;
  imageUploadFinished: boolean = false;
  public updating = false;
  public uploads: Array<FileUpload> = []
  public maxUploads = 10;

  constructor(
    private encounterService: EncountersService,
    private populationService: PopulationService,
    private dialog: MatDialog,
    private dateService: DateService,
    private log: ILoggingService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private imgService: IImageService,
    private workspaceService: WorkspaceService
  ) { }

  ngOnInit(): void {
    this.workspaceService.workspace.subscribe( res => {
      this.populationRoles = res.settings?.populationRoles;
      this.higherPrivileges = (this.populationRoles?.administrator || this.populationRoles?.professional) ?? false;
      this.canEdit = this.higherPrivileges || this.encounter?.user?.id == res.settings?.user?.id;
    })
    // this.populationService.populationRoles.subscribe( res => {
    //   this.populationRoles = res;
    //   this.higherPrivileges = res.administrator! || res.professional!;
    //   this.canEdit = this.higherPrivileges || this.encounter?.user?.id == res.userId
    // })
  }

  toggleEditState() {
    this.editing = !this.editing;
  }

  toggleMarkForDeletionState() {
    this.markForDel = !this.markForDel
    this.markingForDeletion.emit(this.markForDel);

    this.markForSideLabel = false
    this.markingForSideLabel.emit(this.markForSideLabel);
  }
  private pushUpdate(update: UpdateEncounterDetailsDialogData) {

    var textOfDate = this.dateService.formatDateFromAny(update.encounter!.dateString, false) + "";
    const encounterName = `${textOfDate} ${update.encounter!.location!.name} ${update.user!.firstName} ${update.user!.lastName}`;

    update.encounter!.dateTime = new Date(textOfDate +"");

    // @ts-ignore
    const dateTime = (update.encounter!.dateTime == undefined || update.encounter!.dateTime == null ) ? this.encounter?.dateTime : update.encounter!.dateTime;

    var dateTimeParsed = new Date("" + moment(dateTime).format('YYYY-MM-DD'));
    const dto: EncounterCreationRequestDto = {
      populationId: this.encounter?.populationId,
      id: this.encounter?.id,
      submissionUser: update.user,
      dateTime: new Date(Date.UTC(dateTimeParsed.getFullYear(), dateTimeParsed.getMonth(), dateTimeParsed.getDate(), 13, 0, 0)),
      dateString: textOfDate,
      description: update.encounter!.description,
      informNewUser: update.user!.informUser ?? false,
      location: update.encounter!.location,
      name: encounterName,
      predationEvent: update.encounter!.predationEvent,
      predationTargets: update.encounter!.predationTargets,
      complete: update.encounter!.complete,
      completeStatus: update.encounter!.completeStatus,
      archived: update.encounter?.archived,
      encounterBehaviors: update.encounter?.behaviors,
      dataLimitation: update.encounter?.license?.id
    }
    console.log(dto);
    this.encounterService
      .updateEncounter(dto)
      .subscribe({
        next: (value: EncounterDto) => {
          this.log.info(`${dto.name} Updated successfully.`, true);
          if (dto.submissionUser?.email != this.encounterUser ) {
            this.userChanged.emit(dto.submissionUser);
            this.encounterUser = dto.submissionUser
          }
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not update encounter: ${value.message}`, true)
        }
      })
  }

  updateDetails() {
    const ref = this.dialog.open(UpdateDetailsComponent, {
      data: {
        encounter: this.encounter,
        user: this.encounterUser
      },
      minWidth: "680px"
    })
    ref.afterClosed()
      .subscribe(res => {
        if (!res) return;
        this.pushUpdate(res);

      })
  }


  addImages() {
    const ref = this.dialog.open(AddImagesComponent, {
      data: {
        encounter: this.encounter,
        user: this.encounterUser
      }
    })
    ref.afterClosed()
      .subscribe(async res => {
        if (res !== undefined) {
          this.itemsAdded.emit(res);
          this.files = res;
          this.fileItems = res;
          await this.addNewImages(res);
        }
      });
  }

  async addNewImages(files: Array<File>)
  {
    this.fileCounter = 0;
    this.imageUploadStarted = true;
    for (let file of files) {
      this.uploads.push({
        name: file.name,
        percentage: 0,
        done: false,
        file: file,
        failed: false,
        started: false
      })
    }

    const result = this.uploads.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / this.maxUploads)

      if(!resultArray[chunkIndex]) {
        // @ts-ignore
        resultArray[chunkIndex] = [] // start a new chunk
      }

      // @ts-ignore
      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])


    for (let uploadList of result) {
      // @ts-ignore
      for (let element of uploadList) {
        this.uploadFile(element.file)
      }
      // @ts-ignore
      while (uploadList.findIndex(a => !a.done) !== -1) {
        await this.sleep(2000)
      }
    }
  }

  sleep(timeMs: number): Promise<any> {
    return timer(timeMs).toPromise().then(res => {});
  }


  uploadFile(file: File) {
    const endpoint = `${environment.server.imageServer}${environment.server.api.file.delete}/chunk`
    const item = this.uploads.find(a => a.name == file.name);
    item!.done = false;
    item!.percentage = 0;
    item!.failed = false;
    const upload = new Upload(file, {
      endpoint: endpoint,
      retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 10 * 60 * 1000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      removeFingerprintOnSuccess: true,
      headers: {
        encounterId: this.encounter?.id!,
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      onError: (error: any) => {
        const item = this.uploads.find(a => a.name == file.name);
        item!.failed! = true;
      },
      onProgress: (bytesUploaded: any, bytesTotal: any) => {
        const percentage = ((bytesUploaded / bytesTotal) * 100);
        const item = this.uploads.find(a => a.name == file.name);
        item!.percentage! = percentage;
      },
      onSuccess: () => {
        const item = this.uploads.find(a => a.name == file.name);
        item!.percentage! = 100;
        item!.done! = true;
        this.fileCounter++;
        this.uploadsFinished++;
      },
    })

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then( (previousUploads: any)  => {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0])
      }

      // Start the upload
      upload.start()
    })
  }

  confirmImageUpload() {
    this.imageUploadFinished = true;
    this.imageUploadStarted = false;
    this.uploadsFinished = 0;
    // this.reloadRequested.emit();
  }

  requeue() {
    if (this.encounter !== undefined && this.encounter.id !== undefined) {

      this.encounterService
        .requeueUnfinishedAnnotations(this.encounter.id)
        .subscribe({
          next: (_: any) => {
            this.log.info(`Items requeued.`)
          },
          error: (value: HttpErrorResponse) => {
            this.log.error(`Could not requeue items: ${value.error.message}`);
          }
        })
    }
  }

  requeueAll() {
    if (this.encounter !== undefined && this.encounter.id !== undefined) {

      this.encounterService
        .requeueAllItems(this.encounter.id)
        .subscribe({
          next: (_: any) => {
            this.log.info(`Items requeued.`)
          },
          error: (value: HttpErrorResponse) => {
            this.log.error(`Could not requeue items: ${value.error.message}`);
          }
        })
    }
  }

  deleteEncounter() {
    const ref = this.dialog.open(DeleteEncounterDialogComponent,
      {
        data: {encounter: this.encounter, message: ""}
      });
    ref.afterClosed().subscribe( res => {
      if (res) {
        const dto: EncounterDeletionDto = {encounterId: res.encounter.id, message: res.message}
        this.encounterService
          .deleteEncounter(dto)
          .subscribe({
            next: (value: any) => {
              this.log.info("Successfully scheduled encounter for deletion.");
              this.router.navigate(['/encounters'])
            },error: (err: HttpErrorResponse) => {
              this.log.error(`Could not delete encounter: ${err.message}`);
            }
          });
        this.encounterDeleted.emit(this.encounter);
      }
    })


  }

  reassociate() {
    this.encounterService.reassociate(this.encounter!.id!).subscribe({
      next: (value: any) => {
        this.log.info("Ensuring annotations are associated with animals.");
        this.reloadRequested.emit();
      },error: (err: HttpErrorResponse) => {
        this.log.error(`Could not ensure association: ${err.message}`);
      }
    });
  }

  removeModelPredictions() {
    this.updating = true;
    this.encounterService
      .removePredictions(this.encounter!.id!)
      .subscribe({
        next: (value: ResponseDto) => {
          if (value.successful) {
            this.log.info(`Encounter Cleanup Successful`)
            this.reloadRequested.emit();
            this.updating = false;
          } else {
            this.log.info(`Encounter Cleanup Failed: ${value.errorMessages.join(', ')}`)
            this.updating = false;
          }
        }, error: (value: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Removing Extraneous Predictions", value)
          this.updating = false;
        }
      })
  }

  assignSides() {
    this.updating = true;
    this.encounterService.autoassignSide(this.encounter!.id!).subscribe( {
      next: (value) => {
        this.log.info("Encounter images scheduled for assignment. Check back in a bit to see the results.");
        this.updating = false;
      }, error: (value: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Assigning Sides for Images", value);
        this.updating = false;
      }
    })
  }

  mergeEncounter() {
    this.dialog.open(EncounterMergeDialogComponent, {
      data: {
        encounter: this.encounter,
        population: this.population
      }
    })
  }

  toggleMarkSideApplication() {
    this.markForSideLabel = !this.markForSideLabel
    this.markForDel = false;
    this.markingForSideLabel.emit(this.markForSideLabel);
    this.markingForDeletion.emit(this.markForDel);

  }

  finishSideLabelling() {
    this.markForSideLabel = !this.markForSideLabel
    this.markForDel = false;
    this.markingForSideLabel.emit(this.markForSideLabel);
    this.markingForDeletion.emit(this.markForDel);
    const dialog = this.dialog.open(BulkItemActionConfirmationComponent, {
      data: {
        items: this.items,
        action: "side-label",
        title: "Bulk Labelling of Sides"
      },
      maxWidth: "90vw"
    })

    dialog.afterClosed().subscribe( d => {
      if (d !== undefined) {
        this.updating = true;
        const obs = new Array<Observable<any>>();
        for(let i of d.markedImages) {
          i.metaData.side = d.selection
          obs.push(this.imgService.updateMetaData(i.metaData))
        }
        concat(...obs).pipe(
          toArray()
        ).subscribe({
          next: (value) => {
            this.updating = false;
          }
        });
      }
    })
  }

  updateLicense() {

    const dialog = this.dialog.open(SubmissionLicenseVerificationComponent, {
      data: {
        organizationIds: this.encounter?.organizations?.map(a => a.id) ?? [],
        editing: true
      }
    });

    dialog.afterClosed().subscribe( d => {
      if (d !== undefined) {
        this.updating = true;
        this.encounterService.updateEncounterLicense(this.encounter?.id, d)
          .subscribe({
            next: (value: ResponseDto) => {
              if (value.successful) {
                this.log.info("License successfully updated.")
              } else {
                this.log.error(`License could not be updated: ${value.errorMessages.join(',')}`)
              }
              this.updating = false;
            },
            error: (value: HttpErrorResponse) => {
              this.log.error(`License could not be updated: ${value.message}`);
              this.updating = false;
            }
          })
      }
    })
  }

  updateArchivalStatus() {
    const ref = this.dialog.open(ArchivalStatusDialogComponent, {
      data: {
        editing: true,
        encounter: this.encounter
      },
    })

    ref.afterClosed().subscribe(res => {
      if (res) {
        this.pushUpdate({encounter: res, user: this.encounterUser})
      }
    })
  }

  tryPersistMissing() {
    if  (!this.encounter?.id) {return}
    this.encounterService.tryPersistMissingImages(this.encounter!.id!).subscribe(res => {
      this.log.info(`Looking for images to try and persist. Check back soon.`)
    })
  }
}

<div class="profile-image-container content-container">
  <div class="image-container">
    <div class="default" *ngIf="!userImage && user">
      <svg width="100" height="100" [data-jdenticon-value]="getJdenticon(user)"></svg>
    </div>
    <div class="user-profile-image" *ngIf="userImage && user">
      <img [src]="imageService.maybePrefix(userImage.image)" [alt]="user.firstName" >
    </div>
    <div class="image-edit-overlay" *ngIf="!foreign" (click)="openPhotoUpdateDialog()">
      <mat-icon>photo_camera</mat-icon>
      <h2>Update Image</h2>
    </div>
  </div>

</div>


import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PopulationDto} from "../../../../../../../../models/dto/population/populationDto";
import {MatDrawer} from "@angular/material/sidenav";
import {MatPaginator} from "@angular/material/paginator";
import {AnalysisJob} from "../../../../../../../../models/analysis/analysisJob.model";
import {AnalysisService} from "../../../../../../../../services/analysis.service";
import {UuidService} from "../../../../../../../../services/utilities/uuid.service";
import {WorkspaceService} from "../../../../../../../../services/workspace/workspace.service";
import {UserProfileDto} from "../../../../../../../../models/dto/user/userProfileDto";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ILoggingService} from "../../../../../../../../services/logging/logging.service.interface";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {DateService} from "../../../../../../../../services/utilities/date.service";

@Component({
  selector: 'app-social-network-analysis',
  templateUrl: './social-network-analysis.component.html',
  styleUrls: ['./social-network-analysis.component.scss']
})
export class SocialNetworkAnalysisComponent implements OnInit, OnChanges{
  @Input() population?: PopulationDto;

  myForm: FormGroup;


  public creatingExport: boolean = false;
  public submitting: boolean = false;
  public requestingUser?: UserProfileDto

  public request?: AnalysisJob
  activeModule?: string;
  activeElement?: AnalysisJob;

  @ViewChild(MatDrawer) drawer!: MatDrawer;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  jobs: AnalysisJob[] = [];
  displayedColumns: string[] = ['date', 'name', 'startDate', 'endDate',  'status', 'jobType',  'fetch', 'delete'];

  colormaps = [
    'Accent', 'Blues', 'BrBG', 'BuGn', 'BuPu', 'CCF', 'CMRmap', 'GnBu',
    'Greens', 'Greys', 'Oranges', 'OrRd', 'Paired', 'Pastel1', 'Pastel2', 'PiYG',
    'PRGn', 'PuBu', 'PuBuGn', 'PuOr', 'PuRd', 'Purples', 'RdBu', 'RdGy',
    'RdPu', 'RdYlBu', 'RdYlGn', 'Spectral', 'YlGn', 'YlGnBu', 'YlOrBr', 'YlOrRd'
  ];

  fileExtensions = [
    'png', 'jpeg', 'pdf'
  ]

  defaultJob: AnalysisJob = {
    startDate: new Date(),
    endDate: new Date(),
    submissionDate: new Date().toISOString().split("T")[0],
    requestingUser: '',
    status: 'Pending',
    jobType: 'Analysis',
    requestResult: '',
    results: {},
    options: {
      name: '',
      width: 25,
      height: 25,
      inner_scale: 7,
      outer_scale: 14,
      node_size: 750,
      cmap: 'Accent',
      node_alpha: 1.0,
      edge_alpha: 0.5,
      label_size: 10,
      img_format: 'png',
    },
    populationId: 'default-population',
  };
  refreshing: boolean = false;



  constructor(
    private analysisService: AnalysisService,
    private guidService: UuidService,
    private workspaceService: WorkspaceService,
    private fb: FormBuilder,
    private log: ILoggingService,
    private http: HttpClient,
    public date: DateService
  ) {
    this.workspaceService.workspace.subscribe(res => {
      this.requestingUser = res.settings?.user
    })
    this.myForm = this.fb.group({
      startDate: [this.defaultJob.startDate, Validators.required],
      endDate: [this.defaultJob.endDate, Validators.required],
      name: [this.defaultJob.options.name, Validators.required],
      width: [this.defaultJob.options.width, [Validators.required, Validators.min(1)]],
      height: [this.defaultJob.options.height, [Validators.required, Validators.min(1)]],
      inner_scale: [this.defaultJob.options.inner_scale, [Validators.required, Validators.min(1)]],
      outer_scale: [this.defaultJob.options.outer_scale, [Validators.required, Validators.min(1)]],
      node_size: [this.defaultJob.options.node_size, [Validators.required, Validators.min(1)]],
      cmap: [this.defaultJob.options.cmap, Validators.required],
      node_alpha: [this.defaultJob.options.node_alpha, [Validators.required, Validators.min(0), Validators.max(1)]],
      edge_alpha: [this.defaultJob.options.edge_alpha, [Validators.required, Validators.min(0), Validators.max(1)]],
      label_size: [this.defaultJob.options.label_size, [Validators.required, Validators.min(1)]],
      img_format: [this.defaultJob.options.img_format, [Validators.required]],
    });
    this.request = { ...this.defaultJob };

  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("population" in changes) return this.ngOnInit();
  }
  ngOnInit(): void {
    if (!this.population) return;
    this.analysisService.getAllJobs(this.population!.id!).subscribe(data => {
      this.jobs = data;
      this.refreshing = false;
    });
  }

  startExportRequest() {
    if (!this.requestingUser) { return}
    this.activeModule = 'newRequest';
    this.myForm.reset({
      startDate: this.defaultJob.startDate,
      endDate: this.defaultJob.endDate,
      name: this.defaultJob.options.name,
      width: this.defaultJob.options.width,
      height: this.defaultJob.options.height,
      inner_scale: this.defaultJob.options.inner_scale,
      outer_scale: this.defaultJob.options.outer_scale,
      node_size: this.defaultJob.options.node_size,
      cmap: this.defaultJob.options.cmap,
      node_alpha: this.defaultJob.options.node_alpha,
      edge_alpha: this.defaultJob.options.edge_alpha,
      label_size: this.defaultJob.options.label_size,
      img_format: this.defaultJob.options.img_format,
    });

    // Set the new request object with default values
    this.request = { ...this.defaultJob };
    this.drawer.open();
  }


  submit() {
    this.submitting = true;
    if (this.myForm.valid) {
      const formValue = this.myForm.value;

      // Construct the AnalysisJob object with updated values
      this.request = {
        startDate: formValue.startDate,
        endDate: formValue.endDate,
        requestingUser: this.requestingUser!.id!, // Add the requesting user logic as needed
        status: 'Pending', // Add the status logic if needed
        jobType: 'Louvain', // Add the jobType logic as needed
        requestResult: '',
        results: {},
        submissionDate: new Date().toISOString().split("T")[0],
        options: {
          name: formValue.name,
          width: formValue.width,
          height: formValue.height,
          inner_scale: formValue.inner_scale,
          outer_scale: formValue.outer_scale,
          node_size: formValue.node_size,
          cmap: formValue.cmap,
          node_alpha: formValue.node_alpha,
          edge_alpha: formValue.edge_alpha,
          label_size: formValue.label_size,
          img_format: formValue.img_format,

        },
        populationId: this.population!.id!, // Add populationId logic as needed
      };

      this.analysisService.createJob(this.request).subscribe({
          next: (value: AnalysisJob) => {
            this.log.info(`Job request successfully registered. Check back soon.`)
            this.drawer.close();
            this.submitting = false;
            this.ngOnInit();
          }, error: (value: HttpErrorResponse) => {
            this.log.error(`Could not create job request: ${value.error}`)
          this.drawer.close();
          this.submitting = false;

        }
        })
    }
  }

  deleteJob(job: AnalysisJob) {
    this.analysisService.deleteJob(job.id!).subscribe({
      next: (value: any) => {
        this.log.info(`Job request successfully deleted`);
        this.jobs = this.jobs.filter(j => j !== job);
      }, error: (value: HttpErrorResponse) => {
        this.log.error(`Could not delete job: ${value.error}`)
      }
    })
  }

  fetchResults(job: AnalysisJob) {
    job.loading = true;
    this.analysisService.fetchResult(job.id!).subscribe(response => {
      if (response.uri) {
        this.downloadFile(response.uri);
        job.loading = false;
      }
    }, error => {
      console.error('Error fetching file URI:', error);
      job.loading = false;
    });
  }

  private downloadFile(fileUri: string) {
    this.http.get(fileUri, { responseType: 'blob' }).subscribe(blob => {
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger download
      const a = document.createElement('a');
      a.href = url;
      a.download = this.getFileNameFromUri(fileUri);
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Error downloading file:', error);
    });
  }

  private getFileNameFromUri(uri: string) {
    const fileName = uri.split("/").pop();
    if (fileName) {
      return fileName.split("?")[0] || "downloaded_file";
    } return "downloaded_file";
  }

  review(job: AnalysisJob) {
    job.disabled = true;
    this.myForm.setValue({
      startDate: job.startDate,
      endDate: job.endDate,
      name: job.options.name || '',
      width: job.options.width || 25,
      height: job.options.height || 25,
      inner_scale: job.options.inner_scale || 7,
      outer_scale: job.options.outer_scale || 14,
      node_size: job.options.node_size || 750,
      cmap: job.options.cmap || 'Accent',
      node_alpha: job.options.node_alpha || 1.0,
      edge_alpha: job.options.edge_alpha || 0.5,
      label_size: job.options.label_size || 10,
      img_format: job.options.img_format || 'png'
    });
    this.request = job;
    this.activeModule = 'newRequest';
    this.drawer.open();
  }

  refresh() {
    this.refreshing = true;
    this.ngOnInit();
  }
}

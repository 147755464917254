<mat-drawer-container class="editing-sidebar">
  <mat-drawer mode="over" #drawer position="end" class="editing-sidebar-drawer-content">

    <ng-container *ngIf="activeModule === 'updateRole'">
      <ng-container *ngTemplateOutlet="updateRoleContent"></ng-container>
    </ng-container>
    <ng-container *ngIf="activeModule === 'inviteMember'">
      <ng-container *ngTemplateOutlet="inviteMemberContent"></ng-container>
    </ng-container>



<!--    <ng-container *ngIf="activeModule === 'addingBehavior'">-->
<!--      <ng-container *ngTemplateOutlet="behaviorAddContent"></ng-container>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="activeModule === 'updatingBehavior'">-->
<!--      <ng-container *ngTemplateOutlet="behaviorUpdateContent"></ng-container>-->
<!--    </ng-container>-->
  </mat-drawer>
  <mat-drawer-content>
    <div class="content-container">
      <div class="content-header">
        <div class="grouped-buttons">
          <button mat-button (click)="startAdd()"><mat-icon>add</mat-icon>Invite</button>
          <button mat-button (click)="ngOnInit()"><mat-icon>refresh</mat-icon>Refresh</button>
        </div>

        <div class="table-filter">
          <mat-form-field color="accent">
            <mat-label>Filter Members</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Enter name or email" />
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="mat-elevation-z8">
        <table mat-table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="profile">
            <th mat-header-cell *matHeaderCellDef>Profile</th>
            <td mat-cell *matCellDef="let element">
              <a [routerLink]="getProfileLink(element)" mat-icon-button><mat-icon>account_circle</mat-icon></a>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element">
              <a (click)="startEdit(element)">{{element.userProfileDto.firstName}} {{element.userProfileDto.lastName}}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let element"> {{element.userProfileDto.email}} </td>
          </ng-container>
          <ng-container matColumnDef="role" >
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>Role</th>
            <td mat-cell *matCellDef="let element"> {{element.roleName}} </td>
          </ng-container>
          <ng-container matColumnDef="joined">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>Date Joined</th>
            <td mat-cell *matCellDef="let element"> {{getDateString(element.memberSince)}} </td>
          </ng-container>
          <ng-container matColumnDef="confirmed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Confirmed</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.confirmed">check_circle</mat-icon>
              <button *ngIf="!element.confirmed" mat-button (click)="confirmPopulationMember(element)"><mat-icon>add_circle</mat-icon>Confirm</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="deleteUser(element)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                       showFirstLastButtons
                       aria-label="Select page of population behavior">
        </mat-paginator>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #updateRoleContent>
  <div class="content" *ngIf="activeElement && populationRoles">
    <h3>Updating {{activeElement?.userProfileDto?.firstName}} {{activeElement?.userProfileDto?.lastName}}</h3>
    <div class="content-section">
      <mat-form-field color="accent">
        <mat-label>Population Role</mat-label>
        <mat-select [(ngModel)]="activeElement.roleName" (ngModelChange)="activeElement.roleUpdated = true">
          <mat-option *ngFor="let role of populationRoles" [value]="role.normalizedName">{{role.name}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="button-row" *ngIf="activeElement">
    <button mat-button (click)="drawer.close()">Cancel</button>
    <button mat-button (click)="updateRole(activeElement)">Save</button>
  </div>
</ng-template>

<ng-template #inviteMemberContent>

  <div class="content" *ngIf="newUserInvitation">
    <h3 >Add A New Member</h3>
    <div class="content-section">
      <mat-form-field color="accent">
        <mat-label>First Name</mat-label>
        <input matInput [(ngModel)]="newUserInvitation.user.firstName" required>
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Last Name</mat-label>
        <input matInput [(ngModel)]="newUserInvitation.user.lastName" required >
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="newUserInvitation.user.email" required>
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Assigned Role</mat-label>
        <mat-select [(ngModel)]="newUserInvitation.role" required>
          <mat-option *ngFor="let role of populationRoles" [value]="role.id">{{role.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-label>Personalize your invitation</mat-label>
        <textarea matInput [(ngModel)]="newUserInvitation.message"></textarea>
      </mat-form-field>
    </div>

  </div>

  <div class="button-row" *ngIf="newUserInvitation">
    <button mat-button (click)="drawer.close()">Cancel</button>
    <button mat-button (click)="sendInvite()"  [disabled]="!inviteDisabled()">Send Invitation</button>
  </div>
</ng-template>
<ng-template #loading>
  <h4>Getting population members...</h4>
</ng-template>

<div  [class.light-theme]="!dark">
  <div class="population-grid-container" >

    <ng-container *ngFor="let pop of populationOverviews | async">
      <a *ngIf="pop.population != undefined"
         href="javascript:void(0);"
         class="population-grid-item"
         (click)="handlePopulationClick($event, pop)">
        <figure class="population-img" *ngIf="pop.population.banner; else themeImage">
          <img [src]="pop.population.banner" alt="{{pop.population.displayName}}" />

        </figure>
        <ng-template #themeImage>
          <figure class="population-no-img">
            <object data="assets/FinWave.svg"
                    type="image/svg+xml"
                    width="100%"
                    height="100%">
            </object>
          </figure>
        </ng-template>

        <header class="population-header">
          <div class="population-header-left">
            <h2 class="population-header-title">
              <span>{{pop.population.displayName}}</span>
            </h2>
            <div class="population-header-location">
              <span>{{pop.population.center?.name}}</span>
            </div>
            <div class="population-header-spec">
              <span>{{pop.population.speciesName}}</span>
            </div>
          </div>

          <div class="population-header-right">
            <div class="population-header-items">
              <img src="assets/fin.svg"
                   alt="Fin icon">
              <div>{{ formatNumberStr(pop.populationStatistics!.individualCount) }}</div>
            </div>
            <div class="population-header-photos">
              <img src="assets/photo.svg"
                   alt="Photo icon">
              <div>{{ formatNumberStr(pop.populationStatistics!.imageCount) }}</div>
            </div>
          </div>
        </header>

        <div class="population-join-wrap" *ngIf="pop.population!=undefined && pop.isInPopulation==false && pop.requireJoinRequest==true">
          <button class="population-join">
            <img src="assets/lock.svg" alt="">
            <span>Request Access</span>
          </button>
        </div>

        <div class="population-join-wrap" *ngIf="pop.population!=undefined && pop.isInPopulation==false && pop.requireJoinRequest==false">
          <button class="population-join">
            <span>Join Population</span>
          </button>
        </div>

        <footer class="population-footer">
          <div class="population-encounters">
            Encounters: <span>{{formatNumberStr(pop.populationStatistics!.encounterCount) }}</span>
          </div>
          <div class="population-contributors">
            Contributors: <span>{{formatNumberStr(pop.populationStatistics!.memberCount) }}</span>
          </div>
        </footer>
      </a>
    </ng-container>


    <a href="javascript:void(0);" (click)="newPopulation()" class="population-grid-item">
      <div class="add-population-wrap">
        <div class="add-population-btn"><span>+</span></div>
        <div class="add-population-title">Create a New Population</div>
      </div>

    </a>


  </div>

</div>



  <div class="loading-next">
    <app-loading-overlay [criteria]="updating" [title]="'Fetching populations...'"></app-loading-overlay>
  </div>
<app-loading-overlay [criteria]="sendingApplication" [title]="'Registering application...'"></app-loading-overlay>

<div class="component-container">
  <div class="component">
    <div class="component-header">
      <div class="header-balcony">
        <div class="header-title">
          <h1>Submit your data for {{population?.displayName}} <span *ngIf="populationRights !== undefined && (populationRights.administrator || populationRights.professional) && submissionUser !== undefined">as {{submissionUser.firstName}} {{submissionUser.lastName}}</span></h1>
          <h4><b>*By submitting these images you confirm that you have the right to share these data</b></h4>
        </div>

        <div class="buttons desktop">
<!--          <mat-form-field class="complete-select" matTooltip="Confirm whether you are uploading images of all whales present during the encounter." color="accent">-->
<!--            <mat-label>Encounter Completion Status</mat-label>-->
<!--            <mat-select [(ngModel)]="completeStatus" >-->
<!--              <mat-option *ngFor="let completeOption of completeOptions" [value]="completeOption">{{completeOption}}</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->

          <button mat-raised-button color="accent" [disabled]="!formValid() || population === undefined || user === undefined || populationSettings === undefined" (click)="submit()">Submit</button>
          <button mat-stroked-button color="warn" matTooltip="Reset submission form to defaults" (click)="reset()">Reset</button>
        </div>
      </div>
      <div class="main-details">
        <div class="submission-details-main">
          <div class="submission-details-entry" *ngIf="populationRights !== undefined &&  (populationRights.administrator || populationRights.professional)">
            <app-submission-user [submissionUser]="submissionUser" (submissionUserUpdated)="submissionUser = $event"></app-submission-user>
          </div>
          <mat-divider></mat-divider>
          <div class="submission-details-entry">
            <app-submission-organization [organizations]="organizations" [selectedOrganizations]="selectedOrganizations"></app-submission-organization>
          </div>
          <!--              <mat-divider></mat-divider>-->
          <!--              <div class="submission-details-entry">-->
          <!--                <app-submission-limitation  [selectedOrganizations]="selectedOrganizations.value" (updated)="dataLimitation = $event"></app-submission-limitation>-->
          <!--              </div>-->
          <mat-divider></mat-divider>
          <div class="submission-details-entry">
            <app-submission-date [encounterDateTime]="encounterDateTime"></app-submission-date>
          </div>
          <mat-divider></mat-divider>
          <div class="submission-details-entry">
            <app-submission-prey
              *ngIf="population !== undefined && population!.id !== undefined"
              [population]="population"
              (predationEventUpdated)="updatePredationEvent($event)"
              (dialogEvent)="dialogEvent($event)"
            ></app-submission-prey>
          </div>
          <mat-divider></mat-divider>
          <div class="submission-details-entry">
            <app-submission-behavior [population]="population" (behaviorsUpdated)="selectedBehaviors = $event"></app-submission-behavior>
          </div>

          <mat-divider></mat-divider>
          <div class="submission-details-entry">
            <app-submission-notes [notes]="encounterNotes" (notesUpdated)="encounterNotes = $event"></app-submission-notes>
          </div>
        </div>
        <div class="submission-details location-details" *ngIf="!disableLocation">
          <app-submission-location [population]="population" [encounterNewData]="this" (locationUpdated)="updateLocation($event)" style="width: 100%"></app-submission-location>
        </div>
      </div>
        <div class="submission-main-columns">
          <div class="location-selection">
          </div>
          <div class="image-selection-container image-upload">
            <app-submission-images (fileItemsDropped)="this.fileItems = $event" (filesDropped)="files=$event" [fileItems]="fileItems"></app-submission-images>
          </div>



          </div>



    <app-submission-images-preview [fileItems]="fileItems" (fileRemoved)="fileItems = $event"></app-submission-images-preview>


  </div>

</div>
<div class="buttons mobile">
  <mat-checkbox class="button complete-encounter" [(ngModel)]="complete" [labelPosition]="'before'" matTooltip="Does this encounter have identification images for all individuals who were present?">Complete Encounter</mat-checkbox>
  <button mat-raised-button color="accent" [disabled]="!formValid() || population === undefined || user === undefined || populationSettings === undefined" (click)="submit()">Submit</button>
  <button mat-stroked-button color="warn" matTooltip="Reset submission form to defaults" (click)="reset()">Reset</button>
</div>
<div class="submission-loading-container" *ngIf="encounterSaveStarted && !encounterSaveConfirmed">
  <div class="submission-loading-content">
    <div class="spinner-container" *ngIf="encounterSaveStarted &&  uploadsFinished < fileItems.length">
      <mat-progress-spinner [mode]="'indeterminate'" [diameter]="75" [strokeWidth]="2" color="accent"></mat-progress-spinner>
      <div class="spinner-text-container">
        {{currentStepText}}
      </div>
    </div>
    <div class="submission-step-text-list">
      <div class="list-item">
        <div class="list-item-icon">
          <mat-progress-spinner *ngIf="!steps.encounterSaved" [mode]="'indeterminate'" [diameter]="25" [strokeWidth]="2" color="accent"></mat-progress-spinner>
          <mat-icon *ngIf="steps.encounterSaved">check</mat-icon>
        </div>

        <span>Encounter Information saved</span>
      </div>
      <div class="list-item files-list">
        <mat-list class="list-container">
          <mat-list-item *ngFor="let upload of uploads" class="file-list-item">
            <div class="file-list-item-content">
              <span *ngIf="!upload.done && !upload.failed">
                <mat-progress-spinner mode="determinate" [value]="upload.percentage" diameter="24" color="accent"></mat-progress-spinner>
              </span>
              <span *ngIf="!upload.done && !upload.failed && upload.percentage == 0">
                <mat-progress-spinner mode="indeterminate" diameter="24" color="accent"></mat-progress-spinner>
              </span>
              <span *ngIf="upload.done">
                <mat-icon color="accent">check</mat-icon>
              </span>
              <span *ngIf="upload.failed">
                <button mat-icon-button (click)="uploadFile(upload.file)">
                  <mat-icon  color="accent">restart_alt</mat-icon>
                </button>
              </span>
              <span>{{upload.name}}</span>
              <mat-error *ngIf="upload.failed">Upload Failed.</mat-error>
            </div>

          </mat-list-item>
        </mat-list>
      </div>

    </div>
    <div class="steps-finished" *ngIf="encounterSaveFinished && uploadsFinished == fileItems.length">
      <mat-icon>check</mat-icon>
      <span>All Done!</span>
    </div>
    <div class="steps-finished-confirm"  *ngIf="encounterSaveFinished && uploadsFinished == fileItems.length">
      <button mat-raised-button color="accent" (click)="confirmEncounterSave()">Confirm</button>
    </div>
  </div>
</div>
</div>

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PopulationDto} from "../../../../models/dto/population/populationDto";
import {MatLegacyRadioChange as MatRadioChange} from "@angular/material/legacy-radio";
import {PopulationPrey} from "../../../../models/dto/population/populationPrey";
import {HttpErrorResponse} from "@angular/common/http";
import {PopulationService} from "../../../../services/population/population.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {PredationTarget} from "../../../../models/dto/animal/predationTarget";
import {MatLegacySelectChange as MatSelectChange} from "@angular/material/legacy-select";
import { SubmissionPreyLocationComponent } from './submission-prey-location/submission-prey-location.component';
import { Location } from "../../../../models/location/location.model"
import { EncounterDto } from '../../../../models/dto/encounter/encounterDto';
 

@Component({
  selector: 'app-submission-prey',
  templateUrl: './submission-prey.component.html',
  styleUrls: ['./submission-prey.component.scss']
})
export class SubmissionPreyComponent implements OnInit {
  @Input() population: PopulationDto  | undefined;
  @Input() populationId: string | undefined;
  @Input() encounterEdit: EncounterDto | undefined;
  @Input() public predationTargets: Array<PredationTarget> = new Array<PredationTarget>();
  @Input() public predationEvent: boolean = false;
  @Output() predationEventUpdated: EventEmitter<{ predationEvent: boolean, predationTargets: Array<PredationTarget> }> = new EventEmitter<{ predationEvent: boolean; predationTargets: Array<PredationTarget> }>();
  @Output() dialogEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public populationPrey: Array<PopulationPrey> | undefined;

  public availablePrey: Array<PopulationPrey> | undefined;

  public selectedidx: number = 0;

  @ViewChild(SubmissionPreyLocationComponent) preyLocationDialog!: SubmissionPreyLocationComponent;

  constructor(
    private populationService: PopulationService,
    private log: ILoggingService
  ) { }

  ngOnInit(): void {
    if (this.predationEvent) {
      if (this.population == undefined || this.population.id == undefined) {
        {
            this.populationService.getPopulationById(this.populationId!).subscribe(res => {
            this.population = res;
          });
        }
        // @ts-ignore
        this.loadPrey({ value: true, source: {} });
      }
    }
  }

  ngAfterViewInit(): void {
    this.preyLocationDialog.locationUpdated.subscribe((location: Location) => {
      console.log('Location updated:', location);
      this.updateLocation(location);
    });
  }
  reset() {
    this.predationTargets = [];
    this.predationEvent = false;
    this.updatePreyList();
  }

  addPredatedSpecies() {
    this.predationTargets.push({ location: this.encounterEdit?.location, preyId: undefined, count: 1, encounterId: undefined, populationId: this.populationId });
    //this.predationTargets.push({ location: { id:undefined, name: '', latitude: undefined, longitude: undefined, populationId: undefined, nominal: false  }, preyId: undefined, count: 1, encounterId: undefined, populationId: this.populationId });
  }

  removePredatedSpecies(predationTarget: PredationTarget) {
    const idx = this.predationTargets.indexOf(predationTarget);
    this.predationTargets.splice(idx, 1);
    this.updatePreyList()
  }

  changePreyPosition(predationTarget: PredationTarget) {
   
    if (predationTarget.location == null) {
      predationTarget.location = this.encounterEdit?.location;
    }
   
    this.selectedidx = this.predationTargets.indexOf(predationTarget);
    this.dialogEvent.emit(true);
    this.preyLocationDialog.openDialog(predationTarget, this.selectedidx);
    
  }

  loadPrey($event: MatRadioChange) {
    //this.predationEventUpdated.emit({predationEvent: $event.value, predationTargets: []})
    if ($event.value == true) {
      if (this.populationPrey !== undefined) {
        return;
      }
     
      const popid = this.population == undefined ? this.populationId : this.population?.id!;
      this.populationService
        .getPopulationPrey(popid!)
        .subscribe({
          next: (value: Array<PopulationPrey>) => {
            this.populationPrey = value;
            this.availablePrey = value;
            this.log.info(`Population prey loaded: ${this.populationPrey.length} targets found`, true);
          },
          error: (value: HttpErrorResponse) => {
            this.log.info(`Population prey could not be found: ${value.message}`, true);
          }
        });

      //this.preyLocationDialog.locationUpdated.subscribe((location: Location) => {
      //  console.log('Location updated:', location);
      //  this.updateLocation(location);
      //});

    }
  }


  updatePreyList() {
    this.predationEventUpdated.emit({
      predationEvent: this.predationEvent,
      predationTargets: this.predationTargets
    });
  }
  updateLocation($event: Location) {
    console.log("UpdateLocation " + $event!.name);
    this.predationTargets[this.selectedidx].location = $event;
    this.dialogEvent.emit(false);
   // this.updatePreyList()
  }
}
